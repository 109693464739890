import {
	IAdminMenuContentProps,
	IStatusNormalized,
	ISubMenuAdmin,
	Status,
} from '@stores/interfaces';
import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import AdminMenuContactsData from './AdminMenuContactsData';
import AdminMenuActivityData from './AdminMenuActivityData';
import { saveState } from '@stores/contacts/contacts';
import AdminMenuContactsContent from './AdminMenuContactsContent';
import { ProgramUploadProgress } from './ProgramUploadProgress';
import { ACTIVETAB, THEME, USER_ROLES } from '@stores/constants';
import { useTheme } from '@pages/Themes/ThemeContext';

export default function AdminMenuContent(props: IAdminMenuContentProps) {
	const {
		activeMenu,
		activeSubMenu,
		setActiveSubMenu,
		onClick,
		activeSubPatients,
		setActiveSubPatients,
		
	} = props;
	const { t } = useTranslation();
	const user = useTypedSelector(state => state.user);
	const dispatch = useTypedDispatch();
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const { uploadProgress } = useTypedSelector(
		state => state.aiAssistant.program.main,
	);
	const {theme} = useTheme()

	const subMenuHome: ISubMenuAdmin[] = [
		{
			label: t('Admin.data.menu.home.dashboard.newPatients'),
			key: 'new-patients',
			icon: <Badge color="#84caff" />,
			status: 'newPatients',
		},
		{
			label: t('Admin.data.menu.home.dashboard.pendingReview'),
			key: 'pending-review',
			icon: <Badge color="#dabe08" />,
			status: Status.pendingReview,
		},
		{
			label: t('Admin.data.menu.home.dashboard.outOfParameters'),
			key: 'out-of-parameters',
			icon: theme === THEME.VIBRANT ? <Badge color="black" >
							<span className="ant-badge-status-dot" style={{border:'1px solid #fff',color: 'black', background: 'black'}}></span>
						</Badge> : <Badge color="black"  />,
			status: Status.outOfParams,
		},
		{
			label: t('Admin.data.menu.home.dashboard.followUpRequired'),
			key: 'follow-up-required',
			icon: <Badge color="orange" />,
			status: Status.followUpRequired,
		},
		{
			label: t('Admin.data.menu.home.dashboard.escalationRequired'),
			key: 'escalation-required',
			icon: <Badge color="red" />,
			status: Status.escalationRequired,
		},
		{
			label: t('Admin.data.menu.home.dashboard.reviewed'),
			key: 'reviewed',
			icon: <Badge color="green" />,
			status: Status.reviewed,
		},
	];

	const totalUsersStatus = useTypedSelector(state => ({
		newPatients: state.adminDashboardPatient.statsCount.newUsers,
		outOfParams:
			state.adminDashboardPatient.statsCount.outOfParams.evaluation +
			state.adminDashboardPatient.statsCount.outOfParams.letsMove +
			state.adminDashboardPatient.statsCount.outOfParams.omniRom +
			state.adminDashboardPatient.statsCount.outOfParams.survey,
		pendingReview:
			state.adminDashboardPatient.statsCount.pendingReview.evaluation +
			state.adminDashboardPatient.statsCount.pendingReview.letsMove +
			state.adminDashboardPatient.statsCount.pendingReview.omniRom +
			state.adminDashboardPatient.statsCount.pendingReview.survey,
		reviewed:
			state.adminDashboardPatient.statsCount.reviewed.evaluation +
			state.adminDashboardPatient.statsCount.reviewed.letsMove +
			state.adminDashboardPatient.statsCount.reviewed.omniRom +
			state.adminDashboardPatient.statsCount.reviewed.survey,
		escalationRequired:
			state.adminDashboardPatient.statsCount.escalationRequired.evaluation +
			state.adminDashboardPatient.statsCount.escalationRequired.letsMove +
			state.adminDashboardPatient.statsCount.escalationRequired.omniRom +
			state.adminDashboardPatient.statsCount.escalationRequired.survey,
		followUpRequired:
			state.adminDashboardPatient.statsCount.followUpRequired.evaluation +
			state.adminDashboardPatient.statsCount.followUpRequired.letsMove +
			state.adminDashboardPatient.statsCount.followUpRequired.omniRom +
			state.adminDashboardPatient.statsCount.followUpRequired.survey,
	}));

	return (
		<div
			className={`admin-menu-content ${(activeMenu === "downloadApp"
				&& user?.profile?.role != USER_ROLES.USER) ?
				"bg-[#f5f5f5]" : ""
				}`}
		>

			{activeMenu == 'contacts' ? (
				<AdminMenuContactsData {...props} />
			) : activeMenu == 'activity' ? (
				<AdminMenuActivityData {...props} />
			) : activeMenu == 'downloadApp' ? (
				<></>
			) : activeMenu === '' && (
				<div className="submenu-list">
					{!isSuperAdmin && (
						<h3 className="submenu-title">
							{t('Admin.data.menu.home.dashboard.dashboard')}
						</h3>
					)}
					<ul>
						{!isSuperAdmin ? (
							subMenuHome.map((item: ISubMenuAdmin, index) => {
								return (
									<li
										className={`submenu-item ${activeSubMenu === item.key ? 'submenu-item-active' : ''}`}
										key={index}
										onClick={() => {
											setActiveSubMenu(item.key);
											props.onClick(item.key);
											dispatch(
												saveState({
													activeTab: ACTIVETAB.EVALUATION,
													userId: '',
												}),
											);
										}}>
										<span className="icon-container">{item.icon}</span>
										<span className="label-container">{item.label}</span>
										{item.key != 'summary' && (
											<span className="status-count">
												{
													totalUsersStatus[
													item?.status as keyof IStatusNormalized
													]
												}
											</span>
										)}
									</li>
								);
							})
						) : (
							<AdminMenuContactsContent
								onClick={onClick}
								activeSubPatients={activeSubPatients}
								setActiveSubPatients={setActiveSubPatients}
							/>
						)}
					</ul>
				</div>
			)}
			<ul className="user-info-list">
				<div className="max-h-44 overflow-auto">
					{uploadProgress?.map(item => {
						return <ProgramUploadProgress progressData={item} />;
					})}
				</div>
				{activeMenu != "downloadApp" && user?.profile?.role != USER_ROLES.USER &&
					<li className="user-info-item">
						<span className="user-details">
							<p className="user-name-label">{user.profile.fullName}</p>
							<p className="user-email-label">{user.profile.email}</p>
						</span>
					</li>}
			</ul>
		</div>
	);
}