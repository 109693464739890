import { Menu01 } from '@carespace-icons/general/menu01';
import { setCollapsible } from '@stores/aiAssistant/aiAssistant';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import React from 'react';

const AToggleMenu = () => {
	const dispatch = useTypedDispatch();
	const isCollapsible = useTypedSelector(
		state => state.aiAssistant.aiAssistant.isCollapsible,
	);

	return (
		<div
			style={{cursor:'pointer'}}
			onClick={() => dispatch(setCollapsible(!isCollapsible))}>
			<Menu01 color="stroke-gray-400" />
		</div>
	);
};

export default AToggleMenu;
