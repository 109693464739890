import { useTranslation } from 'react-i18next';
import { TimeRangePickerProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, message } from 'antd';
import { useState } from 'react';
import Button from '@atoms/Button';
import './style.css';
import UploadExport from '@carespace-icons/general/uploadExport';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getReportCsv } from '@stores/report/reports';
import { Loading01 } from '@carespace-icons/general/loading01';

const { RangePicker } = DatePicker;

const ExportReports = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const { loading } = useTypedSelector(state => state.reports);
	const [selectedDates, setSelectedDates] = useState<null | (Dayjs | null)[]>(
		[],
	);

	const rangePresets: TimeRangePickerProps['presets'] = [
		{ label: 'Last 24 hours', value: [dayjs().add(-1, 'd'), dayjs()] },
		{ label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
		{ label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
	];

	const onRangeChange = (
		dates: null | (Dayjs | null)[],
		dateStrings: string[],
	) => {
		setSelectedDates(dates || []);
	};

	const downloadCSV = (csvContent: string, startDate: string, endDate: string) => {
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);

		const fileName = `${location.hostname} Export ${startDate} - ${endDate}.csv` || "report.csv"

		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", fileName);

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	};

	const handleExport = async () => {
		try {
			const payload = {
				startDate: selectedDates[0]
					.hour(0)
					.minute(1)
					.second(1)
					.format('YYYY-MM-DD'),
				endDate: selectedDates[1]
					.hour(23)
					.minute(59)
					.second(59)
					.format('YYYY-MM-DD'),
			};
			const data = await dispatch(getReportCsv(payload));
			downloadCSV(data?.payload, selectedDates[0].format('MM-DD-YY'), selectedDates[1].format('MM-DD-YY'));
		} catch (error) {
			message.warning(t('Admin.data.addToReports.dateLimitWarning'));
		}
	};

	return (
		<div className="range-picker">
			<RangePicker
				presets={rangePresets}
				value={selectedDates}
				onChange={onRangeChange}
				style={{ flex: '4', marginLeft: '4px', height: '40px' }}
				disabledDate={(current: Dayjs) =>
					current && current.isAfter(dayjs(), 'day')
				}
				getPopupContainer={node => node.parentNode}
				placement="bottomLeft"
				inputReadOnly
				open
			/>
			<div style={{ display: 'flex', alignItems: 'end', height: '90%' }}>
				<Button onClick={handleExport} size="full" disabled={loading}>
					<span
						style={{
							color: 'white',
							display: 'flex',
							gap: '8px',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						{loading ? (
							<>
								<Loading01 />
								{t('Admin.data.addToReports.downloading')}
							</>
						) : (
							<>
								<UploadExport />
								{t('Admin.data.addToReports.export')}
							</>
						)}
					</span>
				</Button>
			</div>
		</div>
	);
};

export default ExportReports;
