import React, { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getUserById, getUsers } from '@stores/contacts/contacts';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	SelectedUser,
	AdminMenuProps,
	InboxDataMessage,
} from '@stores/interfaces';
import { ACTIVETAB, ADMIN_KEYS, ADMIN_MENU, pagination, ROUTE_KEYS } from '@stores/constants';
import { USER_ROLES } from '@stores/constants';
import { painAssessmentInfoAction } from '@stores/painAssessment';
import {
	getActivityStreamHistory,
	patchActivityStreamHistory,
} from '@stores/activityStream/activityStream';
import { getSelectedUser } from '@stores/user';
import { getFunctionalGoals } from '@stores/functionalGoals';
import AdminMenuSideBar from './AdminMenuSideBar';
import AdminMenuContent from './AdminMenuContent';
import './style.css';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import {
	getAllAdmin,
	getAllFeatureFlags,
	getPendingInvitesList,
	getRegisteredPatientList,
	getStats,
	getSuperAdminFeatures,
	getUnAssignedPatientList,
	setPendingCount,
	setRegisteredCount,
	setUnAssignedCount,
} from '@stores/adminPatient';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { router } from '@routers/routers';

const AdminMenu = (props: AdminMenuProps) => {
	const currentLocation = window.location.pathname;
	const [inboxLoading, setInboxLoading] = useState(false);
	const { handleNavigation } = useBlockNavigation();
	const [activeMenu, setActiveMenu] = React.useState(
		currentLocation.replace('/', ''),
	);
	const [activeSubMenu, setActiveSubMenu] = React.useState(
		currentLocation.replace('/', ''),
	);
	const [selectedItem, setSelectedItem] = React.useState<SelectedUser | null>(
		null,
	);
	const navigate = useNavigate();
	const location = useLocation();
	const user = useTypedSelector(state => state.user);
	const dispatch = useTypedDispatch();
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const [searchQuery, setSearchQuery] = useState('');
	const [inboxData, setInboxData] = useState<InboxDataMessage[]>([]);
	const [profileData, setProfileData] = useState({});
	const [functionalData, setFunctionalData] = useState<[] | undefined>(
		undefined,
	);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [activeSubPatients, setActiveSubPatients] = useState(
		currentLocation.replace('/', ''),
	);
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		if (
			location.pathname === router.NEW_PATIENTS ||
			location.pathname === router.REVIEWED ||
			location.pathname === router.PENDINGREVIEW ||
			location.pathname === router.FOLLOWUPREQUIRED ||
			location.pathname === router.ESCALATIONREQUIRED
		) {
			setActiveMenu('');
			setSelectedItem(null);
		} else if (
			location.pathname === router.UNASSIGNEDPATIENTS ||
			location.pathname === router.PENDINGINVITES ||
			location.pathname === router.REGISTEREDPATIENTS
		) {
			setActiveMenu(ADMIN_KEYS.CONTACTS);
		}
	}, [location.pathname]);

	const isContactClick = useTypedSelector(
		state => state.painAssessment.isContactClick,
	);

	useEffect(() => {
		if (isContactClick) {
			setActiveMenu(ADMIN_KEYS.CONTACTS);
			handleSelectChange(selectedUser);
			dispatch(painAssessmentInfoAction.onContactClick(false));
		}
	}, [isContactClick]);

	const getUserData = async () => {
		let selectedUser = await dispatch(getSelectedUser());
		setProfileData(selectedUser.payload);
		setSelectedItem(selectedUser.payload);
	};

	useEffect(() => {
		if (isSuperAdmin || user?.isPhysioterapist) {
			getUserData();
		}
	}, [isModalOpen]);

	useEffect(() => {
		if (isSuperAdmin || user?.isPhysioterapist) {
			getUserData();
		}
	}, []);

	const fetchInboxData = async () => {
		setInboxLoading(true);
		const inboxData = await dispatch(getActivityStreamHistory());
		setInboxData(inboxData.payload);
		setInboxLoading(false);
	};
	const { users } = useTypedSelector(state => state.contacts.main);

	useEffect(() => {
		if (
			currentLocation.includes(ROUTE_KEYS.DOWNLOAD_APP) ||
			activeMenu === ACTIVETAB.DOWNLOAD_APP
		) {
			setActiveMenu(ACTIVETAB.DOWNLOAD_APP);
			navigate(`${user.id}${router.DOWNLOAD_APP}`);
			setActiveSubMenu('');
		}
		if (currentLocation === router.SELECTUSER) {
			setActiveMenu(ADMIN_KEYS.CONTACTS);
			setActiveSubPatients('');
			setSelectedItem(null);
		} else if (
			currentLocation === router.UNASSIGNEDPATIENTS ||
			currentLocation === router.PENDINGINVITES ||
			currentLocation === router.REGISTEREDPATIENTS
		) {
			setActiveMenu(ADMIN_KEYS.CONTACTS);
			setSelectedItem(null);
		} else if (
			activeMenu != ACTIVETAB.DOWNLOAD_APP &&
			/\/[a-f0-9]{24}\//.test(currentLocation)
		) {
			setActiveMenu(ADMIN_KEYS.CONTACTS);
			setSelectedItem(selectedUser);
		} else if (currentLocation === '/'){
			setActiveMenu('')
		}
		if (currentLocation.includes(ADMIN_KEYS.ACTIVITY)) {
			dispatch(setActiveTab(ADMIN_KEYS.ACTIVITY));
		}
	}, [currentLocation, selectedItem, selectedUser]);

	useEffect(() => {
		if (
			activeMenu === ADMIN_MENU.NEW_PATIENTS ||
			activeMenu === ADMIN_MENU.REVIEWED ||
			activeMenu === ADMIN_MENU.PENDING_REVIEW ||
			activeMenu ===ADMIN_MENU.FOLLOW_UP_REQUIRED ||
			activeMenu === ADMIN_MENU.ESCALATION_REQUIRED ||
			activeMenu === ADMIN_MENU.OUT_OF_PARAMETERS ||
			activeMenu === ADMIN_MENU.DEFAULT
		) {
			activeMenu != ADMIN_MENU.DEFAULT && handleNavigation(`/${activeMenu}`);
		}
	}, [activeMenu, currentLocation]);

	const handleSelectChange = async (selectedOption: SelectedUser) => {
		setProfileData(selectedOption);
		await dispatch(getUserById(selectedOption.id));
		setSelectedItem(selectedOption);
		const pathSegments = location.pathname.split('/');
		if (location.pathname === router.OMNIROMADDEXERCISES) {
			navigate(router.OMNIROMADDEXERCISES);
		} else if (pathSegments.length > 2) {
			pathSegments[1] = selectedOption.id;
			const newPath = pathSegments.join('/');
			navigate(newPath);
		} else {
			navigate(`/${selectedOption?.id}${router.ACTIVITY}`);
			dispatch(setActiveTab(ADMIN_KEYS.ACTIVITY));
		}
	};

	const handleInboxChange = async (selectOption: string) => {
		await dispatch(getUserById(selectOption));
		await dispatch(patchActivityStreamHistory({ id: selectOption }));
		if (inboxData && selectOption) {
			const updatedInboxData = inboxData?.map((item: InboxDataMessage) => {
				return {
					...item,
					unread:
						item.id.toString() === selectOption.toString() ? 0 : item.unread,
				};
			});
			setInboxData(updatedInboxData);
		}
		if (selectOption) {
			navigate(router.ACTIVITY);
		}
	};

	const onChangeHome = (value: string) => {
		if (value === '') {
			setActiveMenu('');
			setActiveSubMenu(ADMIN_MENU.NEW_PATIENTS);
			setActiveSubPatients('');
		} else {
			setActiveMenu(value);
		}
		props.onClick(value);
	};

	const getUsersStats = async () => {
		await dispatch(getStats());
	};

	const fetchFlagData = () => {
		dispatch(getSuperAdminFeatures());
		dispatch(getAllFeatureFlags(user?.clientId));
	};

	useEffect(() => {
		if (!isSuperAdmin) {
			getUsersStats();
			fetchDataAllAdmin(currentPage);
		} else if (isSuperAdmin) {
			fetchFlagData();
			getFetchData();
			fetchDataAllAdmin(currentPage);
		}
	}, []);

	const getFetchData = async () => {
		const payload = {
			...pagination,
			userId: user?.id,
		};
		const unAssigned = await dispatch(getUnAssignedPatientList(pagination));
		const pedningInvite = await dispatch(getPendingInvitesList(pagination));
		const registeredPatient = await dispatch(getRegisteredPatientList(payload));
		dispatch(setUnAssignedCount(unAssigned?.payload?.pagination?.totalCount));
		dispatch(setPendingCount(pedningInvite?.payload?.pagination?.totalCount));
		dispatch(
			setRegisteredCount(registeredPatient?.payload?.pagination?.totalCount),
		);
	};

	const fetchDataAllAdmin = (page: number) => {
		const payload = {
			limit: 10,
			page: page,
			search: '',
		};
		dispatch(getAllAdmin(payload));
	};

	useEffect(() => {
		if (currentLocation === router.ROOT) {
			setActiveSubMenu(ADMIN_MENU.NEW_PATIENTS);
		} else if (location.state && location.state.session) {
			// dispatch(selectUser(selectedUser?.id));
			// setSelectedItem(selectedUser);
			// setActiveMenu('contacts');
		}
	}, [location.state, selectedUser]);

	useEffect(() => {
		if (
			users?.length === 0 ||
			(users === null && (isSuperAdmin || user?.isPhysioterapist))
		) {
			dispatch(getUsers(''));
		}
	}, [users, dispatch]);

	useEffect(() => {
		if (isSuperAdmin || user?.isPhysioterapist) {
			if (activeMenu == ADMIN_KEYS.SUMMARY) {
				setActiveSubMenu(activeMenu);
			} else if (activeMenu == ADMIN_KEYS.ACTIVITY) {
				fetchInboxData();
			} else if (
				activeMenu == ADMIN_KEYS.CONTACTS &&
				user?.profile?.role === USER_ROLES.ADMIN
			) {
				fetchFlagData();
				getFetchData();
			}
		}
	}, [activeMenu]);

	const getProfileData = async () => {
		if (user.isPhysioterapist) {
			let apiResponse = await dispatch(getSelectedUser());
			setProfileData(apiResponse.payload);
		}
	};

	const fetchingData = async () => {
		const data = await dispatch(getFunctionalGoals());
		setFunctionalData(data?.payload?.data);
	};

	useEffect(() => {
		if (selectedItem && !functionalData) {
			fetchingData();
		}
	}, [selectedItem]);

	useEffect(() => {
		if (selectedUser?.id && (isSuperAdmin || user?.isPhysioterapist)) {
			getProfileData();
		}
	}, [selectedUser, dispatch]);

	useEffect(() => {
		if (location.state?.activeMenu) {
			setActiveMenu('');
			setActiveSubPatients('');
		}
	}, [location.state?.activeMenu]);

	return (
		<div className="admin-menu-main-container">
			<AdminMenuSideBar
				onChangeHome={onChangeHome}
				activeMenu={activeMenu}
				setActiveMenu={setActiveMenu}
				setSearchQuery={setSearchQuery}
				setSelectedItem={setSelectedItem}
				onClick={props.onClick}
				selectedItem={selectedItem}
			/>
			<AdminMenuContent
				inboxLoading={inboxLoading}
				selectedItem={selectedItem}
				activeMenu={activeMenu}
				setActiveMenu={setActiveMenu}
				selectedItem={selectedItem}
				handleSelectChange={handleSelectChange}
				setIsModalOpen={setIsModalOpen}
				isModalOpen={isModalOpen}
				setSearchQuery={setSearchQuery}
				profileData={profileData}
				functionalData={functionalData}
				handleInboxChange={handleInboxChange}
				searchQuery={searchQuery}
				activeSubMenu={activeSubMenu}
				setActiveSubMenu={setActiveSubMenu}
				inboxData={inboxData}
				onClick={props.onClick}
				activeSubPatients={activeSubPatients}
				setActiveSubPatients={setActiveSubPatients}
			/>
		</div>
	);
};

export default AdminMenu;
