import {
	completeUploadProgress,
	goToExercise,
	patchSession,
	resetAll,
	saveCompletedExercise,
	setCompleted,
} from '@stores/aiAssistant/program';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { IRebhabProgramExercise, RehabVideoState } from '@stores/interfaces';
import VideoRecord from '@molecules/MPVideoRecord';
import {
	Col,
	ConfigProvider,
	Drawer,
	Empty,
	Progress,
	Row,
	Tooltip,
	Typography,
	message,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as tf from '@tensorflow/tfjs';
import * as handpose from '@tensorflow-models/handpose';
import * as fp from 'fingerpose';
import {
	discardGesture,
	nextGesture,
	playGesture,
	previousGesture,
	submitGesture,
} from './HandGestures/gestures';
import '@tensorflow/tfjs-backend-wasm';
import { VoiceControl } from '@atoms/ARehabPreAssesment/VoiceControl';
import ProgramVideoHeader from './ProgramVideoHeader';
import { Content } from 'antd/lib/layout/layout';
import PatientExerciseInfo from './ProgramExerciseInfo';
import Menu from './Menu';
import ProgressBarUpload from '@atoms/APProgressBarUpload';
import InstructionalVideo from './InstructionalVideo';
import { patientTimeLimit, ROUTE_KEYS } from '@stores/constants';
import NavigationButtons from '@molecules/MPNavigationButtons';
import PreAssesment from '@atoms/AProgramPreAssesment';
import {
	setActiveTab,
	setProgramStateId,
	setStateId,
} from '@stores/aiAssistant/aiAssistant';
import CountDownTimerOnScreen from '@atoms/CountDownTimer/CountDownTimerOnScreen';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { PreCompletionScreen } from './CompletionScreen/PreCompletionScreen';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { router } from '@routers/routers';
import { ReactVideoRecorderProps } from 'react-video-recorder';

tf.setBackend('wasm');
export const Program = () => {
	const { handleNavigation, clearNavigation } = useBlockNavigation();
	const [isUploading, setUploading] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [isMenuOpened, setMenuOpen] = useState(false);
	const [isInfoOpened, setInfoOpen] = useState(false);
	const [flipCamera, setFlipCamera] = useState(false);
	const [cameraId, setCameraId] = useState<string | null>(null);
	const [isPreCompleted, setPreCompleted] = useState(false);
	const [isStartingTimer, setStartingTimer] = useState(false);
	const [videoBlob, setVideoBlob] = useState<null | Blob>(null);
	const [videoState, setVideoState] = useState<RehabVideoState>(
		RehabVideoState.START,
	);
	const [selectedRating, setSelectedRating] = useState<number>(0);
	const [isGestureEnabled, setGestureEnabled] = useState(false);
	const [gesture, setGesture] = useState();
	const [activeStep, setActiveStep] = useState(1);
	const [milliseconds, setMilliseconds] = useState<number>(patientTimeLimit);
	const { t } = useTranslation();
	const {
		exercises,
		currentExercise,
		sessionId,
		transitionTime,
		isAuto,
		isCompleted,
	} = useTypedSelector(state => state.aiAssistant.program.main);
	const fullscreenRef = useRef<HTMLDivElement>(null);
	const videoRef = useRef<ReactVideoRecorderProps>(null);
	const [savedVoice, setSavedVoice] = useState('');
	const [exerciseQueue, setExerciseQueue] = useState<IRebhabProgramExercise[]>(
		[],
	);

	const navigate = useNavigate();
	const dispatch = useTypedDispatch();

	const isFullScreenInstructional = useTypedSelector(
		state => state.rehab.main.fullScreen,
	);
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);

	const runHandpose = async () => {
		const net = await handpose.load();
		const si = setInterval(() => {
			detect(net);
		}, 1500);
	};

	const detect = async net => {
		if (
			isGestureEnabled &&
			videoState != RehabVideoState.RATING &&
			typeof videoRef?.current !== 'undefined' &&
			videoRef?.current !== null &&
			videoRef?.current?.cameraVideo?.readyState === 4
		) {
			const video = videoRef.current.cameraVideo;
			const videoWidth = video.videoWidth;
			const videoHeight = video.videoHeight;

			video.width = videoWidth;
			video.height = videoHeight;

			const hand = await net.estimateHands(video);

			if (hand.length > 0) {
				const GE = new fp.GestureEstimator([
					nextGesture,
					playGesture,
					previousGesture,
					submitGesture,
					discardGesture,
				]);

				setGesture(GE.estimate(hand[0].landmarks, 4));
			}
		}
	};

	useEffect(() => {
		dispatch(resetAll());
		if (exercises.length == 0) {
			handleNavigation(
				`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.ACTIVITY}`,
			);
			clearNavigation();
			dispatch(setActiveTab('activity'));
		}
	}, []);

	useEffect(() => {
		if (gesture && !isFullScreenInstructional) {
			const score = gesture?.gestures.map(prediction =>
				prediction.score > 4 ? prediction.score : null,
			);

			const maxScore = score.indexOf(Math.max.apply(null, score));

			const handPose = gesture?.gestures[maxScore]?.name;

			if (
				handPose === 'play_stop_gesture' &&
				videoState === RehabVideoState.START
			) {
				onStartRecord();
			} else if (
				handPose === 'next_gesture' &&
				videoState === RehabVideoState.START
			)
				onClickNext();
			else if (
				handPose === 'previous_gesture' &&
				videoState === RehabVideoState.START
			)
				onClickPrev();
			else if (
				handPose === 'play_stop_gesture' &&
				videoState === RehabVideoState.RECORDING
			)
				onStopRecord();
			else if (
				handPose === 'submit_gesture' &&
				videoState === RehabVideoState.REPLAYING
			)
				onSubmitRecord();
			else if (
				handPose === 'discard_gesture' &&
				videoState === RehabVideoState.REPLAYING
			)
				onDiscardRecord();
		}
	}, [gesture]);

	useEffect(() => {
		if (
			videoState === RehabVideoState.START &&
			(activeStep < 1 || activeStep > 6) &&
			!isFullScreenInstructional
		) {
			if (
				savedVoice === 'record' ||
				savedVoice === 'yes' ||
				savedVoice === 'yes.' ||
				savedVoice === 'record.'
			) {
				message.success(t('Patient.data.omnirom.recordStarted'));
				onStartRecord();
			} else if (savedVoice === 'next') {
				if (resultExercise < exercises.length - 1) {
					message.success(t('Patient.data.omnirom.switchVideo'));
					onClickNext();
				} else {
					message.success(t('Patient.data.omnirom.alreadyLast'));
				}
			} else if (savedVoice === 'previous') {
				if (resultExercise > 0) {
					message.success(t('Patient.data.omnirom.switchPrevious'));
					onClickPrev();
				} else {
					message.success(t('Patient.data.omnirom.alreadyFirst'));
				}
			}
		} else if (videoState === RehabVideoState.RECORDING) {
			if (savedVoice === 'stop' || savedVoice === 'stop.') {
				message.success(t('Patient.data.omnirom.recordStopped'));
				onStopRecord();
			}
		} else if (videoState === RehabVideoState.RATING) {
			const voiceLowerCase = savedVoice.toLowerCase();
			if (
				voiceLowerCase.includes('too easy') ||
				voiceLowerCase.includes('1') ||
				voiceLowerCase.includes('one')
			) {
				setSelectedRating(1);
			} else if (
				voiceLowerCase.includes('easy') ||
				voiceLowerCase.includes('2') ||
				voiceLowerCase.includes('two')
			) {
				setSelectedRating(2);
			} else if (
				voiceLowerCase.includes('normal') ||
				voiceLowerCase.includes('3') ||
				voiceLowerCase.includes('three')
			) {
				setSelectedRating(3);
			} else if (
				voiceLowerCase.includes('hard') ||
				voiceLowerCase.includes('4') ||
				voiceLowerCase.includes('four')
			) {
				setSelectedRating(4);
			} else if (
				voiceLowerCase.includes('too hard') ||
				voiceLowerCase.includes('5') ||
				voiceLowerCase.includes('five')
			) {
				setSelectedRating(5);
			}
			selectedRating !== 0 && onStopRating();
		} else if (videoState === RehabVideoState.REPLAYING) {
			if (savedVoice === 'no') {
				message.success(t('Patient.data.omnirom.discardText'));
				onDiscardRecord();
			} else if (savedVoice === 'yes') {
				message.success(t('Patient.data.omnirom.saveSuccess'));
				onSubmitRecord();
			}
		}
	}, [savedVoice, selectedRating]);

	VoiceControl((updatedTranscript: string) => {
		!isAuto && setSavedVoice(updatedTranscript);
	});

	useEffect(() => {
		!isAuto && runHandpose();
	}, [isGestureEnabled, isAuto]);

	const resultExercise = exercises.findIndex(
		exer => exer.id === currentExercise?.id,
	);
	const onClickPrev = () => {
		dispatch(goToExercise(exercises[resultExercise - 1]));
	};
	const onClickNext = () => {
		dispatch(goToExercise(exercises[resultExercise + 1]));
	};

	const onToggleMenu = () => {
		setInfoOpen(false);
		setMenuOpen(!isMenuOpened);
	};

	const onToggleInfo = () => {
		setInfoOpen(!isInfoOpened);
		setMenuOpen(false);
	};

	const onFullscreen = async () => {
		isFullscreen
			? await document?.exitFullscreen()
			: await fullscreenRef?.current!.requestFullscreen()!;
	};

	const onFullscreenExit = async () => {
		isFullscreen && (await document?.exitFullscreen());
	};

	const autoRecord = () => {
		setVideoState(RehabVideoState.RECORDING);
		setStartingTimer(true);
	};

	const autoStopRecord = () => {
		videoRef?.current?.handleStopRecording!();
		setStartingTimer(false);
		setVideoState(RehabVideoState.SAVING);

		if (resultExercise != exercises.length - 1) {
			setTimeout(() => setVideoState(RehabVideoState.START), 3000);
		}
	};

	const onStartRecord = () => {
		videoRef?.current?.handleStartRecording();
		if (isAuto) {
			setVideoState(RehabVideoState.READY);
			setTimeout(() => autoRecord(), transitionTime! * 1000);
		} else {
			setVideoState(RehabVideoState.READY);
			setTimeout(() => {
				setStartingTimer(true);
				setVideoState(RehabVideoState.RECORDING);
			}, 3000);
		}
	};

	const onStopRating = () => {
		setVideoState(RehabVideoState.REPLAYING);
		setStartingTimer(false);
	};

	const onStopRecord = () => {
		videoRef?.current?.handleStopRecording!();
		setVideoState(RehabVideoState.SAVING);
		setStartingTimer(false);
		setTimeout(() => setVideoState(RehabVideoState.RATING), 3000);
	};

	const onEndedTimer = () => {
		isAuto ? autoStopRecord() : onStopRecord();
	};

	const onDiscardRecord = () => {
		setVideoBlob(null);
		if (videoRef?.current?.recordedBlobs) videoRef.currentrecordedBlobs = [];
		setSavedVoice('');
		setSelectedRating(0);
		setMilliseconds(patientTimeLimit);
		setVideoState(RehabVideoState.START);
	};

	const submitVideoPatient = (blob?: Blob) => {
		const exerciseData = {
			id: currentExercise?.id,
			name: currentExercise?.name
				? currentExercise?.name
				: currentExercise?.exerciseLibrary?.title,
			video: blob ? blob : videoBlob!,
			programSessionId: sessionId,
			programExerciseId: currentExercise?.id!,
			exerciseDifficultyLevel: selectedRating!,
		};
		const updatedExerciseQueue = [...exerciseQueue, exerciseData];
		setExerciseQueue(prev => [...prev, exerciseData]);
		if (exercises.length === updatedExerciseQueue.length) {
			setPreCompleted(true);
		} else {
			const exerciseIdsInQueue = updatedExerciseQueue.map(item => item.id);
			const missingExercise = exercises.find(
				exercise => !exerciseIdsInQueue.includes(exercise.id),
			);
			if (missingExercise) {
				dispatch(goToExercise(missingExercise));
			}
		}
		dispatch(saveCompletedExercise(currentExercise));
		setSavedVoice('');
	};

	const onRecordFinished = (videoBlob: Blob) => {
		if (videoBlob) {
			setVideoBlob(videoBlob);
			isAuto && submitVideoPatient(videoBlob);
		}
	};

	const onSubmitRecord = async () => {
		setUploading(true);
		submitVideoPatient();
		setVideoBlob(null);
		setUploading(false);
		setVideoState(RehabVideoState.START);
		setMilliseconds(patientTimeLimit);
	};

	useEffect(() => {
		if (isCompleted) {
			patchAndSave();
		}
	}, [isCompleted]);

	const patchAndSave = async () => {
		if (isFullscreen) {
			onFullscreen();
		}
		const data = await dispatch(patchSession(sessionId));
		dispatch(setProgramStateId(data?.payload?.programId));
		if (location.pathname.includes(ROUTE_KEYS.PROGRAM_START)) {
			dispatch(setStateId(data?.payload?.id));
			dispatch(completeUploadProgress(sessionId));
			clearNavigation();
			navigate(
				`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_LIST_SESSIONS}`,
			);
			dispatch(setActiveTab('listSessions'));
		}
		dispatch(setCompleted(false));
	};

	const switchCamera = async () => {
		setCameraId(null);
		try {
			await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
			const devices = await navigator.mediaDevices.enumerateDevices();
			const cameras = devices.filter(device => device.kind === 'videoinput');
			setFlipCamera(cameras.length > 1);

			if (cameras.length > 0) {
				const currentCameraIndex =
					cameras.findIndex(camera => camera.deviceId === cameraId) || 0;

				const nextCameraIndex = (currentCameraIndex + 1) % cameras.length;
				const nextCamera = cameras[nextCameraIndex];

				if (nextCamera.deviceId !== '') {
					return setCameraId(nextCamera.deviceId);
				}

				setTimeout(() => switchCamera(), 1000);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		switchCamera();

		return () => {
			setCameraId(null);
			setFlipCamera(false);
		};
	}, []);

	useEffect(() => {
		const onFullscreenChange = () =>
			setIsFullscreen(Boolean(document.fullscreenElement));

		document.addEventListener('fullscreenchange', onFullscreenChange);
		return () =>
			document.removeEventListener('fullscreenchange', onFullscreenChange);
	}, []);

	if (exercises.length == 0)
		return (
			<Row align="middle" justify="center" className="select-none">
				<Col
					span={12}
					style={{
						height: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Empty
						description={
							<Typography.Title level={3} className="!text-white">
								{t('Patient.data.rehab.rehabEmpty')}
							</Typography.Title>
						}
					/>
				</Col>
			</Row>
		);

	const progressPercent = 100 - (milliseconds / patientTimeLimit) * 100;

	return (
		<ConfigProvider prefixCls="ant">
			<Row
				className="coach-container select-none"
				align="middle"
				justify="center"
				style={{
					height: '100%',
					overflow: isFullscreen ? 'hidden' : '',
					marginTop: '3rem',
				}}>
				<Col
					ref={fullscreenRef}
					style={{
						maxWidth: 1280,
						width: '100%',
					}}>
					{!isUploading &&
						!(activeStep > 0 && activeStep < 6) &&
						!isCompleted &&
						!isPreCompleted && (
							<ProgramVideoHeader
								savedVoice={savedVoice}
								isGestureEnabled={isGestureEnabled}
								isFullscreen={isFullscreen}
								autoStopRecord={autoStopRecord}
								flipCamera={flipCamera}
								videoState={videoState}
								isStartingTimer={isStartingTimer}
								onFullscreen={onFullscreen}
								onToggleMenu={onToggleMenu}
								onToggleInfo={onToggleInfo}
								onStartRecord={onStartRecord}
								onStopRecord={onStopRecord}
								onDiscardRecord={onDiscardRecord}
								onSubmitRecord={onSubmitRecord}
								onEndedTimer={onEndedTimer}
								onStopRating={onStopRating}
								selectedRating={selectedRating}
								setSelectedRating={setSelectedRating}
								switchCamera={switchCamera}
							/>
						)}
					{videoState === RehabVideoState.RECORDING && (
						<Progress
							percent={progressPercent}
							showInfo={false}
							trailColor="#00000080"
							strokeColor="linear-gradient(90deg, #54CDF2 0%, #5B2B99 100%)"
							className="bg-black custom-progress flex items-center justify-center"
						/>
					)}
					<Content
						style={{
							maxWidth: isFullscreen ? '100%' : 1280,
							position: 'relative',
							zIndex: 1,
							background: '#6b2ba0',
							overflow: 'hidden',
						}}
						className="exercise-info">
						<Drawer
							placement="top"
							closable={false}
							style={{ width: '60%' }}
							onClose={onToggleInfo}
							open={isInfoOpened}
							getContainer={false}
							forceRender={isFullscreen}
							styles={{
								body: {
									backgroundColor: 'rgb(0, 0, 0, 0.65)',
									backdropFilter: 'blur(8px)',
								},
							}}
							height="auto">
							<PatientExerciseInfo />
						</Drawer>
						<Drawer
							placement="left"
							closable={false}
							onClose={onToggleMenu}
							open={isMenuOpened}
							getContainer={false}
							forceRender={isFullscreen}
							style={{ height: isMenuOpened ? '100%' : 0 }}
							bodyStyle={{ padding: 0, backgroundColor: '#642cb9e3' }}>
							<Menu onToggleMenu={onToggleMenu} />
						</Drawer>
						<Col
							className="program-video-recorder-container"
							style={{
								background:
									'var(--mainmenu-bg-color)',
								maxWidth: isFullscreen ? '100%' : 1280,
								width: '100%',
								height: isFullscreen ? '100%' : '',
								aspectRatio: isFullscreen ? '' : '16/9',
							}}>
							{activeStep > 0 && activeStep < 6 && (
								<PreAssesment
									setSavedVoice={setSavedVoice}
									setGestureEnabled={setGestureEnabled}
									savedVoice={savedVoice}
									activeStep={activeStep}
									setActiveStep={setActiveStep}
								/>
							)}
							{isUploading && !(activeStep > 0 && activeStep < 6) && (
								<ProgressBarUpload isFullscreen={isFullscreen} />
							)}
							{videoState != RehabVideoState.REPLAYING &&
								!isCompleted &&
								!isPreCompleted &&
								!isUploading &&
								videoState != RehabVideoState.RATING &&
								!isUploading &&
								(videoState == RehabVideoState.READY ||
									videoState == RehabVideoState.RECORDING ||
									videoState == RehabVideoState.START) &&
								!(activeStep > 0 && activeStep < 6) && (
									<InstructionalVideo
										currentExercise={currentExercise!}
										onStartRecord={onStartRecord}
										videoState={videoState}
									/>
								)}
							{!isCompleted && !isPreCompleted && !isUploading && cameraId && (
								<>
									<VideoRecord
										ref={videoRef}
										videoState={videoState}
										videoBlob={videoBlob}
										onRecordFinished={onRecordFinished}
										cameraId={cameraId}
										timeLimit={patientTimeLimit}
										audio={true}
										isFullscreen={isFullscreen}
									/>
									{videoState === RehabVideoState.RECORDING && (
										<div
											className={`absolute left-16 ${isFullscreen ? 'bottom-20' : 'bottom-16'}`}>
											<CountDownTimerOnScreen
												milliseconds={milliseconds}
												setMilliseconds={setMilliseconds}
												limit={patientTimeLimit}
												isStartingTimer={isStartingTimer}
												endedTimer={onEndedTimer}
											/>
										</div>
									)}
									{(activeStep < 1 || activeStep >= 6) && (
										<div
											className={`absolute right-10 ${isFullscreen ? 'bottom-14' : 'bottom-10'} bg-black rounded-full p-2`}>
											<Tooltip title={t('Patient.data.omnirom.fullScreen')}>
												{isFullscreen ? (
													<MdFullscreenExit
														size={30}
														style={{
															verticalAlign: 'middle',
															cursor: 'pointer',
														}}
														onClick={onFullscreen}
														color="white"
													/>
												) : (
													<MdFullscreen
														size={30}
														style={{
															verticalAlign: 'middle',
															cursor: 'pointer',
														}}
														onClick={onFullscreen}
														color="white"
													/>
												)}
											</Tooltip>
										</div>
									)}
								</>
							)}
							{isPreCompleted && !isCompleted && (
								<div>
									<PreCompletionScreen
										exercises={exerciseQueue!}
										setExercises={setExerciseQueue}
										savedVoice={savedVoice}
										onFullscreenChange={onFullscreenExit}
										patchAndSave={patchAndSave}
									/>
								</div>
							)}
							<img
								style={{
									bottom: isFullscreen ? '64px' : '40px',
									left: '40px',
									position: 'absolute',
									zIndex: 2,
								}}
								src={'/images/LogoLetsMove.svg'}
								alt="rehab"
							/>
							{videoState === RehabVideoState.START &&
								!isCompleted &&
								!isPreCompleted &&
								!isUploading &&
								!(activeStep > 0 && activeStep < 6) &&
								!isFullScreenInstructional && (
									<NavigationButtons
										prevHide={resultExercise === 0 || exercises.length === 0}
										nextHide={resultExercise === exercises.length - 1}
										onClickPrev={onClickPrev}
										onClickNext={onClickNext}
									/>
								)}
						</Col>
					</Content>
				</Col>
			</Row>
		</ConfigProvider>
	);
};
