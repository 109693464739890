import { measurementTypes } from "@pages/PatientOnboard/Constants"
import { router } from "@routers/routers";
import { IBodyRegionNormalized, IStatusNormalized } from "@stores/interfaces"

export const BodyRegionNormalized: IBodyRegionNormalized = {
  full: 'Full Body',
  upperBody: 'Upper Body',
  upperRight: 'Upper Right',
  upperLeft: 'Upper Left',
  lowerBody: 'Lower Body',
  lowerRight: 'Lower Right',
  lowerLeft: 'Lower Left',
}

export const navigationConfig = {
  userActivity: { path: router.ACTIVITY },
  activity: { path: router.ACTIVITY },
  myReport: { path: router.AIASSISTANT_MY_REPORT },
  listEvaluation: { path: router.AIASSISTANT_LIST_EVALUATION },
  romSummary: { path: router.AIASSISTANT_ROM_SUMMARY },
  captures: { path: router.AIASSISTANT_CAPTURES },
  startScan: { path: router.AIASSISTANT_START_SCAN },
  startRomScan: { path: router.AIASSISTANT_START_ROM_SCAN },
  postureScan: { path: router.AIASSISTANT_POSTURE_SCAN },
  postureSummary: { path: router.AIASSISTANT_POSTURE_SUMMARY },
  postureCaptures: { path: router.AIASSISTANT_POSTURE_CAPTURES },
  customSummary: { path: router.AIASSISTANT_CUSTOM_SUMMARY },
  customCaptures: { path: router.AIASSISTANT_CUSTOM_CAPTURES },
  generateProgram: { path: router.AIASSISTANT_GENERATE_PROGRAM, state: { isGenerate: true } },
  programs: { path: router.AIASSISTANT_PROGRAMS, state: { isGenerate: false } },
  listSessions: { path: router.AIASSISTANT_LIST_SESSIONS },
  downloadApp: { path: router.DOWNLOAD_APP },
  surveySummary: { path: router.AIASSISTANT_SURVEY_SUMMARY },
  virtualEvaluation: { path: router.AIASSISTANT_VIRTUAL_EVALUATION },
  createReport: { path: router.AIASSISTANT_CREATE_REPORT },
  assignSurvey: {
    path: router.AIASSISTANT_ASSIGN_SURVEY,
    state: { openSurveyModal: true, isGenerate: true },
  },
  createSurvey: {
    path: router.AIASSISTANT_CREATE_SURVEY,
    state: { openSurveyModal: false, isGenerate: false },
  },
  startSurveyUser: { path: router.AIASSISTANT_START_SURVEY_USER },
  newPatients: { path: router.NEW_PATIENTS },
  outOfParams: { path: router.OUTOFPARAMETERS },
  pendingReview: { path: router.PENDINGREVIEW },
  reviewed: { path: router.REVIEWED },
  escalationRequired: { path: router.ESCALATIONREQUIRED },
  noComponentFound: { path: router.AIASSISTANT_PAGENOTFOUND },
  notUnderstandQuestion: { path: router.AIASSISTANT_SEARCHNOTFOUND },
  overview: { path: "overview" }
};

export const StatusNormalized: Omit<IStatusNormalized, 'newPatients'> = {
  pendingReview: 'Pending Review',
  outOfParams: 'Out Of Parameters',
  followUpRequired: 'Follow Up Required',
  escalationRequired: 'Escalation Required',
  reviewed: 'Reviewed'
}

export const limitPagination = 10

export const pagination = {
  isFirstPage: true,
  isLastPage: true,
  currentPage: 0,
  previousPage: 0,
  nextPage: 1,
  pageCount: 0,
  totalCount: 0
}

export const countdownTime = 3000

export const patientTimeLimit = 60000

export const adminTimeLimit = 10000

export const USER_ROLES = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'superadmin',
  USER: 'user'
}

export const ACTIVETAB = {
  HOME: 'home',
  DOWNLOAD_APP: "downloadApp",
  START_SCAN:'startScan',
  ROM_SUMMARY:"romSummary",
  THEME:'theme',
  OPENAI:'OpenAi',
  TEMPLATES:'templates',
  EXPORT_REPORTS:'exportReports',
  INVITE_CODE:'InviteCode',
  PROGRAMS:'programs',
  EVALUATION:'Evaluation',
  OMNIROM:"OmniROM",
  SURVEYS:"Surveys",
  LETSMOVE:"Let's Move",
  ROMSUMMARY:'romSummary',
  LIST_SESSIONS:"listSessions",
  SURVEYSUMMARY:'surveySummary',
  LIST_EVALUATION:'listEvaluation',
  ACTIVE_TAB:'active-tab'
}

export const functionalGoalsMap: Record<string, number[]> = {
  "Recovery & Managing Discomfort": [1, 3, 4],
  "Functional Independence & Daily Living": [2, 8],
  "Performance Enhancement & Injury Prevention": [6, 7],
  "Holistic Well-being & Quality of Life": [5, 9, 10],
};

export const titleToIconMap: Record<string, number> = {
  "Recovery & Managing Discomfort": 1,
  "Functional Independence & Daily Living": 2,
  "Performance Enhancement & Injury Prevention": 3,
  "Holistic Well-being & Quality of Life": 4
};

export const MONTHS = [
  { value: 1, label: 'Jan' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Apr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Aug' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dec' },
]

export const DEFAULT_FORM_DATA = {
    firstName: '',
    lastName: '',
    email: '',
    imageUrl: '',
    birthDate: '',
    gender: '',
    imperialHeight: 0,
    metricHeight: 0,
    imperialWeight: 0,
    metricWeight: 0,
    isPregnant: false,
    consentPolicyRead: false,
    patientId: '',
    measurementSystem: measurementTypes.IMPERIAL,
    mobilePhone: '',
    weight:'',
    height: ''
  };

  export const THEME = {
    VIBRANT: 'vibrant',
    DARK: 'dark',
    DEFAULT: 'default'
  }
  
  export const activityKeys = ["sitting", "standing", "bending", "lifting", "walking", "sleeping"];

  export const ROUTE_KEYS ={
    DOWNLOAD_APP: "download-app",
    PROGRAM_START:'/program/start',
    ROM_SCAN:'/rom/start-scan',
    POSTURE_SCAN:'/rom/posture-analytics/scan',
    ADD_EXERCISE:'/omni-rom/add-new-exercises'
  }

  export const ADMIN_MENU_CLICK ={
    PROFILE:'profile',
    LOGOUT:'logout',
    ASSESSMENT:'assessment',
    THEME_SELECTOR:'theme-selector',
    SETTINGS:'settings',
    TOOLS:'tools'
  }

  export const ADMIN_MENU = {
    NEW_PATIENTS: 'new-patients',
    REVIEWED: 'reviewed',
    PENDING_REVIEW: 'pending-review',
    FOLLOW_UP_REQUIRED: 'follow-up-required',
    ESCALATION_REQUIRED: 'escalation-required',
    OUT_OF_PARAMETERS: 'out-of-parameters',
    DEFAULT: ''
  };
  
  export const ADMIN_KEYS = {
    CONTACTS: 'contacts',
    ACTIVITY:'activity',
    SUMMARY:'summary',
    TOOLS:'tools',
    USERACTIVITY:'userActivity',
    GENERATE_PROGRAM:'generateProgram'
  };

  export const SORT_KEYS ={
    CREATED_AT:'createdAt'
  }
