import { useEffect, useState } from 'react';
import OmniRom from './OmniRom';
import Rehab from './Rehab';
import Survey from './Survey';
import { Badge, Tabs, TabsProps, Tag } from 'antd';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { useTranslation } from 'react-i18next';
import Evaluation from './Evaluation';
import { clearState } from '@stores/contacts/contacts';
import AToggleMenu from '@atoms/AToggleMenu';
import { ACTIVETAB } from '@stores/constants';

const { TabPane } = Tabs;

function index() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(ACTIVETAB.EVALUATION)
  const dispatch = useTypedDispatch()
  const savedState = useTypedSelector(state => state.contacts.main.ptDashboard)
  const escalationRequiredStatCount = useTypedSelector(state => state.adminDashboardPatient.statsCount.escalationRequired)
  const count = escalationRequiredStatCount.evaluation + escalationRequiredStatCount.letsMove + escalationRequiredStatCount.omniRom + escalationRequiredStatCount.survey;
  const TAB_ITEM: TabsProps['items'] = [
    {
      key: ACTIVETAB.EVALUATION, label: <div className={`dashboard-badge-count-div ${activeTab === ACTIVETAB.EVALUATION ? ACTIVETAB.ACTIVE_TAB : ''}`}>
        <Badge color="red" count={escalationRequiredStatCount.evaluation}>
          <span className='dashboard-badge-label-span'>{t('Admin.data.pendingReview.omniRom.evaluation')}</span></Badge></div>,
      disabled: false, children: <Evaluation />
    },
    {
      key: ACTIVETAB.OMNIROM, label: <div className={`dashboard-badge-count-div ${activeTab === ACTIVETAB.OMNIROM ? ACTIVETAB.ACTIVE_TAB : ''}`}><Badge color="red" count={escalationRequiredStatCount.omniRom}>
        <span className='dashboard-badge-label-span'>{t('Admin.data.escalationRequired.omniRom.omniRom')}</span></Badge></div>, disabled: false, children: <OmniRom />
    },
    {
      key: ACTIVETAB.LETSMOVE, label: <div className={`dashboard-badge-count-div ${activeTab === ACTIVETAB.LETSMOVE ? ACTIVETAB.ACTIVE_TAB : ''}`}><Badge color="red" count={escalationRequiredStatCount.letsMove}>
        <span className='dashboard-badge-label-span'>{t('Admin.data.managePatient.letsMove.letsMove')}</span></Badge></div>, disabled: false, children: <Rehab />
    },
    {
      key: ACTIVETAB.SURVEYS, label: <div className={`dashboard-badge-count-div ${activeTab === ACTIVETAB.SURVEYS ? ACTIVETAB.ACTIVE_TAB : ''}`}><Badge color="red" count={escalationRequiredStatCount.survey}>
        <span className='dashboard-badge-label-span'>{t('Admin.data.pendingReview.omniRom.surveys')}</span></Badge></div>, disabled: false, children: <Survey />
    }
  ]

  useEffect(() => {
    setActiveTab(savedState.activeTab || ACTIVETAB.EVALUATION)
  }, [])
  
  return (
    <div className="total-patient-main-div">
      <h3 className='gap-2'><AToggleMenu /><span className='badge-css'><Badge color='red'/></span> {t('Admin.data.escalationRequired.escalationRequired')} <span className='total-patient-length'><Tag color="purple">{count} {t('Admin.data.escalationRequired.users')}</Tag></span>
      </h3>
      <Tabs onChange={(value) => {
         dispatch(clearState())
        setActiveTab(value)
      }}
        activeKey={activeTab}
        className="custom-tabs"
      >
        {TAB_ITEM.map(item => (
          <TabPane tab={item.label} key={item.key}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default index