import { useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Popover, Avatar, Button, Layout, Dropdown, Menu, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Edit02 } from '@carespace-icons/general/edit02';
import { useEffect, useState } from 'react';
import ProfileModal from '@pages/Profile/ProfileModal';
import UserDetails from '@atoms/AdminMenu/UserDetails';
import { getFunctionalGoals } from '@stores/functionalGoals';
import { getUser } from '@stores/user';
import './style.css';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { setActiveTab, setStartSession } from '@stores/aiAssistant/aiAssistant';
import { useBlockNavigation } from '@atoms/ABlockNavigation';
import { router } from '@routers/routers';
import { UseAuth } from '@contexts/AuthContext';
import { ADMIN_MENU_CLICK, ROUTE_KEYS, USER_ROLES } from '@stores/constants';
import {
	getProgramListApproved,
	selectExercise,
	selectProgram,
} from '@stores/aiAssistant/program';
import { status } from '@pages/PatientOnboard/Constants';
import HeightInfo from '@atoms/AdminMenu/HeightInfo';
import { Logomark } from '@carespace-icons/Logo/logomark';
import { IMenuAdminProps } from '@stores/interfaces';
import Sider from 'antd/es/layout/Sider';
import { navigationConfig } from '@stores/constants';
import { useMenuConstants } from './UserMenuLists';
import ThemeSelector from '@pages/Themes/ThemeSelector';
import { FaCaretLeft } from 'react-icons/fa';
import { useAdminMenuConstants } from '@atoms/AdminMenu/AdminMenuLists';
import { ACTIVETAB } from '../../../stores/constants';

const UserMenu = () => {
	const { t } = useTranslation();
	const { handleNavigation } = useBlockNavigation(
		location.pathname.includes(ROUTE_KEYS.PROGRAM_START),
		t('Admin.data.menu.aiAssistant.aiAssistantListSessions.unsavedChanges'),
	);
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	const program = useTypedSelector(
		state => state.aiAssistant.program.programApproved,
	);
	const [collapsed, setCollapsed] = useState(false);
	const [profileMenuOpen, setProfileMenuOpen] = useState(false);
	const isSuperAdmin = user?.profile?.role === USER_ROLES.SUPER_ADMIN;
	const currentLocation = window.location.pathname;
	const isCorrectLocation = currentLocation.includes(ROUTE_KEYS.PROGRAM_START);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [policyModalOpen, setPolicyModalOpen] = useState(false);
	const [functionalData, setFunctionalData] = useState();
	const [profileData, setProfileData] = useState({});
	const [isProgramAvailable, setProgramAvailable] = useState(false);
	const [activeMenu, setActiveMenu] = useState(
		currentLocation.replace('/', ''),
	);
	const { menuMapping } = useAdminMenuConstants();

	const buttonStyle = {
		color: 'var(--button-text-color)',
		border: 'inherit',
		width: '100%',
		margin: '10px 0',
	};
	const { mainMenu, menuUser, avatarMenus } = useMenuConstants();
	const { credentials, onLogout } = UseAuth();

	const activeTab = useTypedSelector(
		state => state.aiAssistant.aiAssistant.activeTab,
	);

	const logout = () => {
		onLogout();
	};
	const handleClick = () => handleNavigation(router.ROOT);

	useEffect(() => {
		user?.id && fetchProgramData(1);
	}, [user]);

	useEffect(() => {
		activeTab != '' && setActiveMenu('tools');
		currentLocation === '/' && setActiveMenu('');
	}, [activeTab, currentLocation]);

	const fetchProgramData = async (pageNumber: number) => {
		const payload = {
			userId: user?.id,
			limit: 10,
			page: pageNumber,
			searchValue: '',
			status: status.APPROVED,
		};
		const data = await dispatch(getProgramListApproved(payload));
		if (data?.payload?.data?.length! > 0) {
			setProgramAvailable(true);
		} else {
			setProgramAvailable(false);
		}
	};

	const popoverContent = (item: string) => {
		return <span className="user-popup-label-capitalize">{item}</span>;
	};

	const fetchingData = async () => {
		const data = await dispatch(getFunctionalGoals());
		setFunctionalData(data?.payload?.data);
	};

	useEffect(() => {
		if (!isSuperAdmin && !user?.isPhysioterapist) {
			fetchingData();
		}
	}, [dispatch]);

	const getUserData = async () => {
		if (credentials?.sub) {
			const user = await dispatch(getUser(credentials.sub));
			setProfileData(user.payload);
		}
	};

	useEffect(() => {
		setProfileData(user);
	}, [user]);

	useEffect(() => {
		if (!isSuperAdmin && !user?.isPhysioterapist) {
			getUserData();
		}
	}, []);

	const handleStartSession = () => {
		if (program.data?.length == 1) {
			dispatch(selectProgram(program.data[0]));
			dispatch(selectExercise(program.data[0].exercises));
			navigate(`/${user.id}${router.AIASSISTANT_PROGRAM_START}`);
			dispatch(setActiveTab(''));
			dispatch(setStartSession(false));
		} else if (program.data?.length! > 1) {
			dispatch(setActiveTab(ACTIVETAB.PROGRAMS));
			dispatch(setStartSession(true));
			handleNavigation(`/${user?.id}${router.AIASSISTANT_PROGRAMS}`);
		}
	};

	const [toolsMenuOpen, setToolsMenuOpen] = useState(false);
	const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

	const getMenu = (options: any[], parentKey: string) => (
		<div className="flex items-center justify-center custom-dropdown-menu-sidebar">
			<div style={{
				right: "95.9%",
				position: "absolute"
				}}>
				{options?.length > 0 && <FaCaretLeft color="white" size={20} />}
			</div>
			<Menu
				onMouseLeave={() => {
					setDropdownVisible(false);
					setToolsMenuOpen(false);
				}}
				className="custom-space-dropdown">
				{options?.map((option, idx) => (
					<Menu.Item
						key={idx}
						onClick={() => {
							if (
								option.key != ADMIN_MENU_CLICK.THEME_SELECTOR &&
								option?.key != ADMIN_MENU_CLICK.SETTINGS &&
								option.key != ADMIN_MENU_CLICK.LOGOUT
							) {
								navigate(`/${user?.id}${navigationConfig[option.key].path}`);
								setActiveMenu(parentKey);
							}
						}}>
						<Space className="flex items-center p-1 hover:bg-slate-100 hover:rounded-lg">
							{option.icon}{' '}
							{option.key != ADMIN_MENU_CLICK.THEME_SELECTOR ? (
								<span
									style={{
										color:
											option.key === activeTab ? 'var(--tab-text-color)' : '#000',
									}}>
									{option.name || option.label}
								</span>
							) : (
								<ThemeSelector />
							)}
						</Space>
					</Menu.Item>
				))}
			</Menu>
		</div>
	);

	return (
		<>
			<Layout style={{ minHeight: '100vh' }}>
				<Sider width={100} style={{ background: 'var(--mainmenu-bg-color)' }}>
					<ul className="user-menu">
						<li
							className="li-logo"
							onClick={() => {
								handleClick();
								setActiveMenu('');
							}}>
							<Logomark />
						</li>
						{mainMenu?.map((item: IMenuAdminProps, index) => {
							if (item.key === '') {
								return (
									<Popover
										key={index}
										content={popoverContent(item.label)}
										title=""
										placement="right">
										<li
											className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
											onClick={() => {
												handleClick();
												setActiveMenu('');
											}}
											key={index}
											title={item.key}>
											{item.icon}
										</li>
									</Popover>
								);
							} else if (item.key === ADMIN_MENU_CLICK.TOOLS) {
								return (
									<Popover
										key={index}
										content={
											<div>
												{menuUser.map(menuItem => (
													<div key={menuItem.key} className="relative">
														<Dropdown
															overlay={getMenu(menuItem.options, item.key)}
															className={`p-2`}
															placement="right"
															trigger={['hover']}
															onOpenChange={visible => {
																setDropdownVisible(visible);
															}}
															overlayStyle={{
																position: 'absolute',
																left: '220px',
															}}>
															<span
																className="cursor-pointer flex items-center gap-2 hover:bg-slate-100 hover:rounded-lg"
																style={{
																	color:
																		menuItem.key === menuMapping[activeTab]
																			? 'var(--tab-text-color)'
																			: '#000',
																}}
																onClick={() => {
																	if (menuItem?.key === 'activity') {
																		navigate(
																			`/${user?.id}${navigationConfig[menuItem.key].path}`,
																		);
																		setDropdownVisible(false);
																		setToolsMenuOpen(false);
																	}
																}}>
																{menuItem.icon} {menuItem.label}
															</span>
														</Dropdown>
													</div>
												))}
											</div>
										}
										placement="right"
										open={toolsMenuOpen}
										onOpenChange={visible => {
											if (visible) {
												setToolsMenuOpen(visible);
											} else {
												!dropdownVisible && setToolsMenuOpen(visible);
											}
										}}>
										<li
											className={`menu-item ${activeMenu === item.key ? 'menu-item-active' : ''}`}
											onClick={() => setToolsMenuOpen(prev => !prev)}>
											{item.icon}
										</li>
									</Popover>
								);
							}
						})}
					</ul>
					<ul style={{ bottom: '25px', left: '40px', position: 'absolute' }}>
						<Popover
							content={
								<>
									{avatarMenus.map(menuItem => (
										<li
											key={menuItem.key}
											className="cursor-pointer flex items-center p-2 hover:bg-slate-100 hover:rounded-lg"
											onClick={() => {
												if (
													menuItem.key != 'theme-selector' &&
													menuItem.key != 'logout'
												) {
													navigate(
														`/${user?.id}${navigationConfig[menuItem.key].path}`,
													);
													setActiveMenu('tools');
												} else if (menuItem.key === 'logout') {
													logout();
												}
												setProfileMenuOpen(false);
											}}>
											{menuItem?.key != 'theme-selector'
												? menuItem.label
												: menuItem.icon}
										</li>
									))}
								</>
							}
							placement="right"
							open={profileMenuOpen}
							onOpenChange={setProfileMenuOpen}>
							<span className="cursor-pointer flex items-center">
								<>
									{user?.profile?.imageUrl ? (
										<Avatar
											src={user?.profile?.imageUrl}
											alt="avatar"
											size={30}
										/>
									) : (
										<Avatar
											style={{
												backgroundColor:
													user?.profile?.avatarColor || '#1890FF',
												color: '#FFF',
												fontSize: '20px',
												border: '2px solid white',
												display: 'inline-flex',
												alignItems: 'center',
											}}
											alt="avatar"
											size={30}>
											{user.profile?.firstName.charAt(0).toUpperCase()}
										</Avatar>
									)}
								</>
							</span>
						</Popover>
					</ul>
				</Sider>

				<Sider
					width={320}
					collapsible
					collapsed={collapsed}
					onCollapse={setCollapsed}
					trigger={null}>
					{!collapsed && (
						<ul className="user-menu-content">
							<li style={{ position: 'relative', padding: '16px' }}>
								<div className="avatar-container">
									<>
										{user?.profile?.imageUrl ? (
											<Avatar
												src={user?.profile?.imageUrl}
												alt="avatar"
												size={100}
											/>
										) : (
											<Avatar
												style={{
													backgroundColor:
														user?.profile?.avatarColor || '#1890FF',
													color: '#FFF',
													fontSize: '50px',
													border: '4px solid white',
													display: 'inline-flex',
													alignItems: 'center',
												}}
												alt="avatar"
												size={100}>
												{user.profile?.firstName.charAt(0).toUpperCase()}
											</Avatar>
										)}
									</>
									<div
										className="edit-image-icon"
										style={{
											backgroundColor: 'var(--button-color-primary)',
											height: '30px',
											width: '30px',
											textAlign: 'center',
										}}
										onClick={() => {
											setIsModalOpen(true);
										}}>
										<div className="mt-1">
											<Edit02 width={20} height={20} />
										</div>
									</div>
									{isModalOpen && (
										<ProfileModal
											isModalOpen={isModalOpen}
											setIsModalOpen={setIsModalOpen}
											setPolicyModalOpen={setPolicyModalOpen}
											policyModalOpen={policyModalOpen}
											closable={true}
											onEdit={true}
										/>
									)}
									<p
										className="first-name-label-text"
										style={{ lineHeight: '0.9' }}>
										{user?.profile?.firstName} {user?.profile?.lastName}
									</p>
									<p className="email-label-text">{user?.profile?.email}</p>
									<HeightInfo userDetails={profileData} />
									{!isCorrectLocation && isProgramAvailable && (
										<Button
											className="start-session-css flex items-center"
											style={buttonStyle}
											icon={
												<PlayCircle
													height={18}
													width={18}
													color="var(--button-text-color)"
												/>
											}
											onClick={e => {
												e.stopPropagation();
												handleStartSession();
											}}>
											{t('Patient.data.myProgress.rehab.startSession')}
										</Button>
									)}
								</div>
								<UserDetails
									userDetails={user}
									functionalData={functionalData}
								/>
							</li>
						</ul>
					)}
				</Sider>
			</Layout>
		</>
	);
};

export default UserMenu;
