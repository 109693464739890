import ImgFront from '@atoms/ABodyFront';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	createSession,
	fetchExercises,
	getOmniRomExerciseGroups,
	setStrapiOmniRomExerciseGroupId,
} from '@stores/rom/main';
import { Button, Col, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import './style.css';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import { bodyPartMapping, mainTemplateGroup } from './ProgramGroup';
import { setVideoRecordState } from '@stores/onBoard/onBoard';
import { PlayCircle } from '@carespace-icons/media/playCircle';
import { showCustomModal } from '@atoms/ACustomModalInfo';
import {
	fetchOmniromProgram,
	fetchStrapiExercises
} from '@stores/rom/romTemplates';
import { TOmniromSelectedExercise } from '@stores/interfaces';

const bodyPoints = [
	{
		id: 0,
		name: 'Right Shoulder',
		position: 'front',
		styles: { top: '96px', left: '63px' },
		part: 'rightShoulder',
	},
	{
		id: 1,
		name: 'Left Shoulder',
		position: 'front',
		styles: { top: '96px', right: '63px' },
		part: 'leftShoulder',
	},
	{
		id: 2,
		name: 'Right Elbow',
		position: 'front',
		styles: { top: '174px', left: '47px' },
		part: 'rightElbow',
	},
	{
		id: 3,
		name: 'Left Elbow',
		position: 'front',
		styles: { top: '174px', right: '47px' },
		part: 'leftElbow',
	},
	{
		id: 6,
		name: 'Right Hip',
		position: 'front',
		styles: { top: '215px', left: '72px' },
		part: 'rightHip',
	},
	{
		id: 7,
		name: 'Left Hip',
		position: 'front',
		styles: { top: '215px', right: '72px' },
		part: 'leftHip',
	},
	{
		id: 8,
		name: 'Right Knee',
		position: 'front',
		styles: { bottom: '138px', left: '97px' },
		part: 'rightKnee',
	},
	{
		id: 9,
		name: 'Left Knee',
		position: 'front',
		styles: { bottom: '138px', right: '97px' },
		part: 'leftKnee',
	},
	{
		id: 9,
		name: 'Right Wrist',
		position: 'front',
		styles: { top: '238px', left: '25px' },
		part: 'rightWrist',
	},
	{
		id: 10,
		name: 'Left Wrist',
		position: 'front',
		styles: { top: '238px', right: '25px' },
		part: 'leftWrist',
	},
	{
		id: 11,
		name: 'Neck',
		position: 'front',
		styles: { top: '75px', left: '115px' },
		part: 'neck',
	},
	{
		id: 12,
		name: 'Spine',
		position: 'front',
		styles: { top: '200px', left: '115px' },
		part: 'spine',
	},
];

const btnClass = '!h-auto !text-white !rounded-full !py-[8px] !px-[24px]';

interface ISplashPageProps {
	onTogglesSplashPage: () => void;
}

const SplashPage = (props: ISplashPageProps) => {
	const { onTogglesSplashPage } = props;
	const dispatch = useTypedDispatch();
	const [activePart, setActivePart] = useState('');
	const { user, selectedUser } = useTypedSelector(state => ({
		user: state.user,
		selectedUser: state.contacts.main.selectedUser,
	}));
	const [selectedDropdown, setSelectedDropdown] = useState('');
	const [selectedBodyPointOptions, setSelectedBodyPointOptions] = useState([]);
	const [dropdownOptions, setDropdownOptions] = useState([])
	const [savedExercise, setSavedExercise] = useState<TOmniromSelectedExercise>()

	useEffect(() => {
		fetchData();
	},[])

	const fetchData = async () => {
		const data = await dispatch(fetchStrapiExercises());
		setDropdownOptions(data?.payload?.data);
	}

	const createSessionAndSetId = async (programId: number | string) => {
		dispatch(setStrapiOmniRomExerciseGroupId(programId));
		const session = {
			strapiOmniRomProgramId: programId,
			userId: user.isPhysioterapist ? selectedUser?.id : user.id,
		};
		return dispatch(createSession(session));
	};

	const handleDropdownChange = async (programId: number | string) => {
		try {
			const response = await dispatch(
				fetchOmniromProgram({ omniRomProgramId: Number(programId) }),
			);
			setSelectedBodyPointOptions(response?.payload[0]?.exercises);
		} catch (error) {
			console.error('Error fetching exercises:', error);
		}
	};

	const onToggleSplash = async (programId: number | string) => {
		dispatch(setVideoRecordState(true));
		await createSessionAndSetId(programId);
		onTogglesSplashPage();
	};

	const highlightArea = (bodyPart: string) => {
		const el = document.getElementById('highlght');
		if (el) {
			el.classList.remove('upper', 'right', 'left', 'full', 'lower', 'spine');
			switch (bodyPart) {
				case 'upperBody':
					el.classList.add('full');
					break;
				case 'upperLeft':
					el.classList.add('upper', 'right');
					break;
				case 'upperRight':
					el.classList.add('upper', 'left');
					break;
				case 'lowerBody':
					el.classList.add('lower');
					break;
				case 'lowerLeft':
					el.classList.add('lower');
					break;
				case 'lowerRight':
					el.classList.add('lower');
					break;
				case 'full':
					el.classList.add('full');
					break;
				case 'spine':
					el.classList.add('spine');
					break;
				case 'baseline':
					el.classList.add('baseline');
					break;
				default:
					break;
			}
		}
		setActivePart(bodyPart.toLowerCase());
	};

	const setActiveHighlight = (part: string) => {
    const upperBodyParts = ["shoulder", "elbow", "wrist", "neck"];
    const lowerBodyParts = ["knee", "hip", "spine"];
		const baselineParts = ["shoulder", "hip"];

    const isUpperBody = activePart === "upperbody" && upperBodyParts.some(p => part.includes(p));
    const isLowerBody = activePart === "lowerbody" && lowerBodyParts.some(p => part.includes(p));
    const isUpperRight = activePart === "upperright" && part.includes("right") && upperBodyParts.some(p => part.includes(p));
    const isUpperLeft = activePart === "upperleft" && part.includes("left") && upperBodyParts.some(p => part.includes(p));
    const isLowerRight = activePart === "lowerright" && part.includes("right") && lowerBodyParts.some(p => part.includes(p));
    const isLowerLeft = activePart === "lowerleft" && part.includes("left") && lowerBodyParts.some(p => part.includes(p));
    const isSpine = activePart === "spine" && part.includes("neck");
		const isBaseline =  activePart === "baseline" && baselineParts?.some(p => part.includes(p));

    return (activePart === "full" || isUpperBody || isLowerBody || isUpperRight || isUpperLeft || isLowerRight || isLowerLeft || isSpine || isBaseline || activePart === part) 
        ? "hotspot-circle active" 
        : "hotspot-circle";
};


	const activeStyle = { display: 'inline' };
	const notActiveStyle = { display: 'none' };
	const { t } = useTranslation();

	const buttonStyle = {
		color: '#533F85',
		border: 'inherit',
		width: '100%',
		marginTop: '16px',
	};

	return (
		<Content
			style={{
				aspectRatio: '16/9',
			}}>
			<div className={'splash orom-splash-wrapper'}>
				<div
					style={{
						position: 'absolute',
						left: '100px',
						top: '100px',
						width: '250px',
					}}>
					<Select
						placeholder="Select an Exercise"
						options={dropdownOptions?.map(exercise => ({
							label: exercise?.title,
							value: exercise?.id,
						}))}
						style={{ width: '100%' }}
						dropdownStyle={{ minWidth: '225px' }}
						allowClear
						value={selectedDropdown || undefined}
						onChange={value => {
							setSelectedDropdown(value);
							handleDropdownChange(value);
							const selectedExercise = dropdownOptions?.find(
								ex => ex?.id === value,
							);
							if (selectedExercise) {
						   	setSavedExercise(selectedExercise);
								const bodyPart = bodyPartMapping[selectedExercise?.title] || 'full';
								highlightArea(bodyPart);
							}
						}}
					/>
				</div>
				<div className="body-points select-none">
					<div className="title-top md:scale[50%] lg:scale-[60%]">
						<h3>{t('Patient.data.omnirom.chooseScan')}</h3>
					</div>
					<div>
						{mainTemplateGroup?.map(group => {
							return (
								<Button
									key={group.id}
									className={`btn-outline ${btnClass}`}
									onClick={() => onToggleSplash(group?.id)}
									style={eval('(' + group?.frontend?.styles + ')')}
									onMouseEnter={() => {
										highlightArea(group?.key);
										setSelectedDropdown('');
										setSelectedBodyPointOptions([]);
									}}>
									{group?.name}
								</Button>
							);
						})}
					</div>
					<Row
						justify="center"
						className="md:scale-[40%] lg:scale-[55%] xl:scale-[70%] 2xl:scale-[90%] mt-6">
						<Col className="md:-top-56 lg:-top-20 xl:top-10 2xl:top-16">
							<div className="body-shape-front">
								<div
									className="area-highlight"
									id="highlght"
									style={activePart ? activeStyle : notActiveStyle}></div>
								<ImgFront color="#DCCDE9" width={250} />
								{bodyPoints.map((hotspot, index) => (
									<div
										key={index}
										style={hotspot.styles}
										className={setActiveHighlight(
											hotspot.part.toLowerCase(),
										)}></div>
								))}
							</div>
						</Col>
					</Row>
				</div>
				{selectedBodyPointOptions?.length > 0 && (
					<div
						style={{
							position: 'absolute',
							right: '50px',
							top: '100px',
							width: '370px',
							background: 'white',
							borderRadius: '12px',
							padding: '16px 16px 10px 16px',
						}}>
						<div
							style={{
								color: 'black',
								display: 'flex',
								flexDirection: 'column',
								gap: '14px',
								fontSize: '16px',
								maxHeight: '400px',
								overflowY: 'auto',
							}}>
							{selectedBodyPointOptions?.map((group: any) => {
								return (
									<div className="flex">
										<div
											onClick={e => {
												e.stopPropagation();
												showCustomModal({
													video: group?.OmniRomExerciseId?.video?.url,
													name: group?.OmniRomExerciseId?.name,
													description: group?.OmniRomExerciseId?.description,
												});
											}}
											className="custom-image-container">
											<div className="w-28 h-20 flex-shrink-0 image-wrapper">
												<img
													src={group?.OmniRomExerciseId?.image?.url}
													className="rounded-lg w-full h-full"
												/>
												<div className="play-button">
													<PlayCircle width={40} height={40} />
												</div>
											</div>
										</div>
										<div style={{ width: '9rem', marginLeft: '-30px' }}>
											<p className="text-gray-500 font-bold text-base">
												{group?.OmniRomExerciseId?.name}
											</p>
											<p className="text-gray-500 truncate overflow-hidden text-ellipsis whitespace-nowrap w-36">
												{group?.OmniRomExerciseId?.description
													? group?.OmniRomExerciseId?.description
													: t('Patient.data.omnirom.noDescription')}
											</p>
										</div>
									</div>
								);
							})}
						</div>
						<Button
							className="start-session-css"
							style={buttonStyle}
							icon={
								<PlayCircle height={18} width={18} color="stroke-purple-700" />
							}
							onClick={() => onToggleSplash(selectedDropdown)}>
							{t('Admin.data.managePatient.omniRom.startScan')}
						</Button>
							<div className='flex items-center justify-center text-gray-500 mt-1'>
							<span style={{marginRight: '4px', fontWeight: '600'}}>{t('Admin.data.managePatient.omniRom.estimatedTime')}</span> {savedExercise?.estimatedMinutes} {t('Admin.data.managePatient.omniRom.min')} {savedExercise?.estimatedSeconds} {t('Admin.data.managePatient.omniRom.sec')} 
						</div>
					</div>
				)}
			</div>
		</Content>
	);
};

export default SplashPage;
