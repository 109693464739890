import ExportReports from './ExportReports';
import { Modal, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { APIKeyData } from '@stores/interfaces';
import OpenAiSettings from './OpenAi';
import InviteCodeSettings from './InviteCode';
import { useEffect, useState } from 'react';
import './style.css';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import Features from './Features';
import { ACTIVETAB } from '@stores/constants';
import Templates from './Templates';
import { getEmailTemplate, getInviteTemplate } from '@stores/settings/setings';
import ThemeSelector from '@pages/Themes/ThemeSelector';
import { useAdminMenuConstants } from '@atoms/AdminMenu/AdminMenuLists';

interface SettingProps {
	isModalOpen: boolean;
	setIsModalSettingsOpened: (value: boolean) => void;
	settingData: APIKeyData | undefined;
	apiKey: string;
	setApiKey: (value: string) => void;
	inviteCode: string;
}

const Settings = (props: SettingProps) => {
	const {
		isModalOpen,
		setIsModalSettingsOpened,
		settingData,
		apiKey,
		setApiKey,
		inviteCode,
	} = props;

	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(ACTIVETAB.OPENAI);
	const allFeatureFlags = useTypedSelector(
		state => state.adminDashboardPatient.allFeatureFlags,
	);
	const [features, setFeatures] = useState<string[]>([]);
	const user = useTypedSelector(state => state.user);
	const dispatch = useTypedDispatch();
	const {settingsMenu} = useAdminMenuConstants()

	useEffect(() => {
		if (isModalOpen) {
			const allFeatureIds = allFeatureFlags
				.map(feature => feature.featureId)
				.filter((id): id is string => id !== undefined);
			setFeatures(allFeatureIds);
			fetchData();
		}
	}, [isModalOpen, allFeatureFlags]);

	const fetchData = async () => {
		await dispatch(getEmailTemplate());
		await dispatch(getInviteTemplate());
	};

	return (
		<Modal
			title={
				<div style={{ textAlign: 'center', marginTop: '8px' }}>
					<span className="setting-title">
						{t('Admin.data.menu.setting.title')}
					</span>
				</div>
			}
			width="800px"
			destroyOnClose={true}
			open={isModalOpen}
			closable={activeTab === ACTIVETAB.THEME ? false : true}
			onCancel={() => {setIsModalSettingsOpened(false)}}
			maskClosable={activeTab === ACTIVETAB.THEME ? false : true}
			footer={null}
			style={{ top: '25%', userSelect: 'none' }}>
			<Tabs
				activeKey={activeTab}
				onChange={setActiveTab}
				items={settingsMenu.map(item => ({
					key: item.key,
					label: item.label,
					children:
						item.key === ACTIVETAB.OPENAI ? (
							<OpenAiSettings
								{...{
									isModalOpen,
									setIsModalSettingsOpened,
									settingData,
									apiKey,
									setApiKey,
								}}
							/>
						) : item.key === ACTIVETAB.INVITE_CODE ? (
							<InviteCodeSettings {...{ inviteCode }} />
						) : item.key === ACTIVETAB.EXPORT_REPORTS ? (
							<ExportReports />
						) : item.key === ACTIVETAB.TEMPLATES ? (
							<Templates setIsModalSettingsOpened={setIsModalSettingsOpened} />
						) : item.key === ACTIVETAB.THEME ? (
							<ThemeSelector isSettings={true} isModalOpen={isModalOpen} setIsModalSettingsOpened={setIsModalSettingsOpened}/>
						) : (
							<Features
								features={features}
								setFeatures={setFeatures}
								setIsModalSettingsOpened={setIsModalSettingsOpened}
							/>
						),
				}))}
			/>
		</Modal>
	);
};

export default Settings;
