import CheckBox from '@atoms/CheckBox';
import { tableDataType, tablePropsType } from '@atoms/TableList/TableIterfaces';
import { ArrowDown } from '@carespace-icons/arrows/arrowDown';
import { ArrowUp } from '@carespace-icons/arrows/arrowUp';
import { Edit01 } from '@carespace-icons/general/edit01';
import { Trash01 } from '@carespace-icons/general/trash01';
import { getUserById } from '@stores/contacts/contacts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import Avatar from '@atoms/Avatar/Avatar';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { setActiveTab } from '@stores/aiAssistant/aiAssistant';
import { router } from '@routers/routers';

const TableList = (props: tablePropsType) => {
	const { t } = useTranslation();
	const { tableData, header, rowSelect, onSelect, onSort, onActionClick } =
		props;

	const [tableListData, setTableListData] = useState<tableDataType[]>([]);
	const [sortKey, setSortKey] = useState('');
	const [sortBy, setSortBy] = useState('asc');
	const [selectedAll, setSelectedAll] = useState(false);
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);

	useEffect(() => {
		setTableListData(tableData || []);
	}, [tableData]);

	const onClickSort = (value: string, type: string) => {
		setSortBy(type);
		setSortKey(value);
		onSort(value);
	};

	const toggleSelectAll = (checked: boolean) => {
		tableListData.forEach((item: tableDataType) => (item.isSelected = checked));
		const selectedItem = tableListData.filter(item => item.isSelected);
		setSelectedAll(checked);
		onSelect && onSelect(selectedItem);
	};

	const onClickCheckbox = (id: string, checked: boolean) => {
		tableListData.forEach((item: tableDataType) => {
			if (item.id == id) {
				item.isSelected = checked;
			}
		});
		const selectedItem = tableListData.filter(item => item.isSelected);
		onSelect && onSelect(selectedItem);
		setSelectedAll(selectedItem.length === tableListData.length ? true : false);
	};

	const onClickDelete = (obj: tableDataType) => {
		onActionClick && onActionClick('delete', [obj]);
	};

	const onClickEdit = (obj: tableDataType) => {
		onActionClick && onActionClick('edit', [obj]);
	};

	const onClickSelectAll = (name: string, value: boolean) => {
		toggleSelectAll(!selectedAll);
	};

	const handleUserChange = async (userId: string) => {
		await dispatch(getUserById(userId));
		navigate(`/${userId}${router.AIASSISTANT_PROGRAMS}`);
		dispatch(setActiveTab('programs'));
	};

	return (
		<div className="w-full rounded-md shadow-lg border-gray-200 border-2" style={{
		backgroundColor: "#f2f4f7"
		}}>
			<table className="w-full">
				<thead>
					<tr className="border-b-gray-200 border-b-2 h-11 font-semi-bold  text-gray-600 text-xs">
						{rowSelect && (
							<th className="text-left pl-2 pr-2">
								<CheckBox
									id={'selectAll'}
									onClick={onClickSelectAll}
									checked={selectedAll}
								/>
							</th>
						)}
						{header &&
							header.map((item, index) => {
								return (
									<th
										className={twJoin(
											'text-left pl-2 pr-2',
											item.sort && 'cursor-pointer',
										)}
										key={index}
										onClick={() =>
											onClickSort(item.key, sortBy == 'asc' ? 'desc' : 'asc')
										}>
										{item.name}
										{item.sort && sortKey == item.key && (
											<span className="ml-4 inline-block">
												{sortBy == 'asc' ? (
													<ArrowDown
														color="stroke-gray-600"
														width={10}
														height={10}
													/>
												) : (
													<ArrowUp
														color="stroke-gray-600"
														width={10}
														height={10}
													/>
												)}
											</span>
										)}
									</th>
								);
							})}
					</tr>
				</thead>
				<tbody>
					{tableListData && tableListData.length > 0 ? (
						tableListData.map((rowData: tableDataType, rowIndex) => {
							return (
								<tr
									key={rowIndex}
									className="border-b-gray-200 border-b-2 text-sm text-gray-600 cursor-pointer"
									onClick={() => handleUserChange(rowData.id)}>
									{rowSelect && (
										<td className="pl-2 pr-2 align-middle p-2 text-sm">
											<CheckBox
												id={`${rowData.id}`}
												onClick={onClickCheckbox}
												checked={rowData.isSelected}
											/>
										</td>
									)}
									<td className="pl-2 pr-2 flex align-middle p-2 ">
										<div className="new-patient-data">
											<Avatar user={rowData} />
											<span className="align-middle">
												<span className="block text-gray-900">
													<strong>
														{rowData.profile?.firstName}{' '}
														{rowData.profile?.lastName}
													</strong>
												</span>
												<span className="block text-gray-600">
													{rowData.profile?.email}
												</span>
											</span>
										</div>
									</td>
									<td className="pl-2 pr-2 align-right p-2">
										{rowData?.userToolStatus &&
											rowData?.userToolStatus?.length > 0 ? (
											<span className="text-primary-700 font-medium text-xs rounded-2xl bg-primary-50 pt-1 pb-1 pl-2 -ml-[8px]">
												<span className="text-xl">&bull;</span>
												{t('Patient.data.rehab.assignLetsMove')}
											</span>
										) : (
											<div>
											<span className="w-[60px] text-blue-700 font-medium text-xs rounded-2xl bg-blue-50 pl-2 -ml-[8px] flex items-center gap-[2px]">
												<span className="text-xl">&bull;</span>
												<span className='mt-[2px]'>{t('Patient.data.rehab.new')}</span>
											</span>
											</div>
										)}
									</td>
									<td className="pl-2 pr-2 align-right p-2">
										<span className='ml-[-3px]'>{rowData?.userToolStatus &&
											rowData?.userToolStatus.length > 0
											? moment(rowData?.userToolStatus[0].createdAt).fromNow()
											: moment(rowData.createdAt).fromNow()}</span>
									</td>
									<td className="pl-2 pr-2 align-right p-2">
										<span
											className="inline-block mr-2 cursor-pointer"
											onClick={() => onClickDelete(rowData)}>
											{rowData.delete && (
												<Trash01
													color="stroke-gray-600"
													width={15}
													height={16}
												/>
											)}
										</span>
										<span
											className="inline-block cursor-pointer"
											onClick={() => onClickEdit(rowData)}>
											{rowData.edit && (
												<Edit01
													color="stroke-gray-600"
													width={15}
													height={16}
												/>
											)}
										</span>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan={header?.length} className="text-center p-3">
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={t('Admin.data.newPatients.noPatientsFound')}
								/>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default TableList;
