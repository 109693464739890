export const jointsTemplateData = [
  {
    key: 'rightShoulder',
    joint: 'Right Shoulder',
    position: { top: 85, left: 62 },
  },
  {
    key: 'leftShoulder',
    joint: 'Left Shoulder',
    position: { top: 85, left: 142 },
  },
  {
    key: 'rightHip',
    joint: 'Right Hip',
    position: { top: 185, left: 71 },
  },
  {
    key: 'leftHip',
    joint: 'Left Hip',
    position: { top: 185, left: 135 },
  },
  {
    key: 'rightKnee',
    joint: 'Right Knee',
    position: { top: 298, left: 87 },
  },
  {
    key: 'leftKnee',
    joint: 'Left Knee',
    position: { top: 298, left: 117 },
  },
  {
    key: 'rightElbow',
    joint: 'Right Elbow',
    position: { top: 150, left: 45 },
  },
  {
    key: 'leftElbow',
    joint: 'Left Elbow',
    position: { top: 150, left: 160 },
  },
  {
    key: 'rightWrist',
    joint: 'Right Wrist',
    position: { top: 198, left: 30 },
  },
  {
    key: 'leftWrist',
    joint: 'Left Wrist',
    position: { top: 198, left: 175 },
  },
  {
    key: 'neck',
    joint: 'Neck',
    position: { top: 75, left: 103 },
  },
  {
    key: 'spine',
    joint: 'Spine',
    position: { top: 170, left: 103 },
  },
];