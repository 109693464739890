import { Layout, Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import MMainMenu from '@molecules/MMainMenu';
import Sider from 'antd/es/layout/Sider';
import PatientOnboard from '@pages/PatientOnboard';
import { useEffect, useState } from 'react';
import { activityKeys, ROUTE_KEYS, USER_ROLES } from '@stores/constants';
import NewPatientOnboardDashboard from '@pages/NewPatientOnboardDashboard';
import { UseAuth } from '@contexts/AuthContext';
import { IWholeDayActivity } from '@stores/interfaces';
import { setCollapsible } from '@stores/aiAssistant/aiAssistant';
import { ThemeProvider } from '@pages/Themes/ThemeContext';
import { router } from '@routers/routers';

const { Content } = Layout;

const MLayoult = () => {
	const { user } = useTypedSelector((state) => ({
		user: state.user,
	}));
	const currentLocation = window.location.pathname
	const { credentials } = UseAuth();
	const isCollapsible = useTypedSelector(state => state.aiAssistant.aiAssistant.isCollapsible)
	const dispatch = useTypedDispatch()
	const role = credentials?.roles ? credentials.roles[0] : undefined;
	const [isLoading, setLoading] = useState(true)
	const [isUserDataPresent, setIsUserDataPresent] = useState(false);
	const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
	const onBoardCompletion = useTypedSelector((state) => state.onBoard.onBoard.onBoardCompletion)
	useEffect(() => {
		if (user?.profile?.role == USER_ROLES.USER || role == USER_ROLES.USER) {
			const isWeight =
				(user?.profile?.imperialWeight || user?.profile?.metricWeight) ||
				(user?.profile?.weight?.[0]?.imperialWeight || user?.profile?.weight?.[0]?.metricWeight);

			const userDataCheck = (
				user?.profile?.firstName &&
				user?.profile?.lastName &&
				user?.profile?.email &&
				user?.profile?.birthDate &&
				(user?.profile?.imperialHeight || user?.profile?.metricHeight) &&
				isWeight &&
				user?.profile?.gender &&
				(user?.functionalGoals?.length > 0) &&
				user?.profile?.consentPolicyRead && getTotalHours(user?.dailyActivityDistribution)
			)
			setIsUserDataPresent(userDataCheck);
		} else {
			setIsUserDataPresent(true);
		}
		user?.id && setLoading(false)
	}, [user]);

	const getTotalHours = (data: IWholeDayActivity): number => {
		if (!data) return 0;
		
		const total = activityKeys
			.map(key => data[key as keyof IWholeDayActivity] || 0)
			.reduce((sum, value) => sum + (typeof value === "number" ? value : 0), 0);
		return total;
	};

	const handleOnboardingComplete = () => {
		setIsOnboardingComplete(true);
	};

	useEffect(() => {
		if (
			currentLocation.includes(ROUTE_KEYS.DOWNLOAD_APP) &&
			(user?.profile?.role === USER_ROLES.ADMIN ||
				user?.profile?.role === USER_ROLES.SUPER_ADMIN)
		) {
			dispatch(setCollapsible(true));
		} else if(currentLocation.includes(ROUTE_KEYS.PROGRAM_START) || currentLocation.includes(ROUTE_KEYS.POSTURE_SCAN) || currentLocation.includes(ROUTE_KEYS.ROM_SCAN) || currentLocation.includes(ROUTE_KEYS.ADD_EXERCISE)) {
			dispatch(setCollapsible(true));
		}
	}, [currentLocation]);

	if (isLoading) {
		return (
			<Spin
				className="flex items-center justify-center w-[100%] h-[100%]"
				tip="Loading"
				size="large"
			/>
		);
	}

	return (
		<ThemeProvider>
			<Layout>
				{!isUserDataPresent && !isOnboardingComplete && !onBoardCompletion ? (
					<PatientOnboard onComplete={handleOnboardingComplete} />
				) : (
					<>
						<Sider
							collapsed={isCollapsible}
							width="400px"
							style={{ minHeight: '100vh' }}>
							<MMainMenu user={user} />
						</Sider>
						<Layout style={{ zIndex: 1, backgroundColor: 'var(--layout-bg-color)' }}>
							<Content>
								{!isUserDataPresent ? (
									<NewPatientOnboardDashboard />
								) : (
									<Outlet />
								)}
							</Content>
						</Layout>
					</>
				)}
			</Layout>
		</ThemeProvider>
	);
};

export default MLayoult;
