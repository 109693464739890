import { AddToReports } from '@atoms/AddToReports'
import { ArrowLeft } from '@carespace-icons/arrows/arrowLeft'
import { LetsMoveSessions } from '@pages/AiAssistant/Conversation/LetsMoveSessions'
import { ListEvaluation } from '@pages/AiAssistant/Conversation/ListEvaluation'
import { RomCaptures } from '@pages/AiAssistant/Conversation/RomCaptures'
import AiAssistantRomSummary from '@pages/AiAssistantRomSummary'
import AiAssistantSurveySummary from '@pages/AiAssistantSurveySummary'
import { AiAssistantKeys, IAddButtonItemsProps } from '@stores/interfaces'
import { Modal } from 'antd'
import './ReportPreviewModal.css'
import { setIsReportModal } from '@stores/aiAssistant/aiAssistant'
import { useTypedDispatch } from '@stores/index'
import { clearReports } from '@stores/report/reports'
import { useTranslation } from 'react-i18next'
import AiPostureSummary from '@pages/AiPosture/AiPostureSummary'
import { THEME } from '@stores/constants'

interface IReportPreviewModalProps {
  isVisible: boolean
  setIsVisible: (val: boolean) => void
  activeComponent: IAddButtonItemsProps
}

export default function ReportPreviewModal(props: IReportPreviewModalProps) {
  const { isVisible, setIsVisible, activeComponent } = props
  const dispatch = useTypedDispatch();
  const {t} = useTranslation()
  const handleCancel = () => {
    setIsVisible(false);
    dispatch(setIsReportModal(false));
    dispatch(clearReports());
  }

  const isDark = document.documentElement.getAttribute("data-theme") === THEME.VIBRANT

  return (
    <Modal
      title={<div className='report-preview-title' onClick={handleCancel}>
        <ArrowLeft width={30} height={30} color={`${isDark ? 'stroke-black':'stroke-primary-800'}`} /><span className="titleStyle">{t("Admin.data.addNotes.add")} {activeComponent.label}</span>
      </div>}
      open={isVisible}
      onCancel={handleCancel}
      footer={false}
      width={1205}
      style={{ userSelect: 'none' }}
      className='review-modal-css'
      maskClosable={false}
    >
      <div style={{backgroundColor:'var(--p-text-color-root)', padding:'4px'}}>
      <div className="soap-add-to-report-css">
        <AddToReports setIsVisible={setIsVisible} />
      </div>
      <div>
        {activeComponent.key === AiAssistantKeys.listEvaluation && <ListEvaluation />}
        {activeComponent.key === AiAssistantKeys.romSummary && <AiAssistantRomSummary />}
        {activeComponent.key === AiAssistantKeys.captures && <RomCaptures />}
        {activeComponent.key === AiAssistantKeys.listSessions && <LetsMoveSessions />}
        {activeComponent.key === AiAssistantKeys.surveySummary && <AiAssistantSurveySummary />}
        {activeComponent.key === AiAssistantKeys.postureSummary && <AiPostureSummary />}
      </div>
      </div>
    </Modal>
  )
}
