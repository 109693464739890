import { Edit01 } from '@carespace-icons/general/edit01';
import { InfoCircle } from '@carespace-icons/general/infoCircle';
import { Save01 } from '@carespace-icons/general/save01';
import { XClose } from '@carespace-icons/general/xClose';
import { patchRomBodyPoints } from '@stores/rom/customRom';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	ICustomRomBodyPoints,
	ICustomRomExercise,
	ICustomRomSessionExercise,
} from '@stores/interfaces';
import { Card, Image, Input, List, message, Progress, Tooltip, Collapse, Table, Button, Tag, Modal, Flex, Typography, Popover, Rate } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { PlusOutlined, MinusOutlined, InfoCircleOutlined, PictureOutlined, EditOutlined } from "@ant-design/icons";
import { Check } from '@carespace-icons/general/check';

import './style.css';

const { Text } = Typography;
interface ICustomSummaryContent {
	item: ICustomRomSessionExercise[];
}

const CustomSummaryContentResult = (props: ICustomSummaryContent) => {
	const { item } = props;
	const kinematicsArray = [
		'flexion',
		'extension',
		'abduction',
		'external rotation',
		'internal rotation',
	];
	const { t } = useTranslation();
	const { user } = useTypedSelector(state => ({
		user: state.user,
	}));

	const [editedPostures, setEditedPostures] = useState<{
		[key: string]: ICustomRomBodyPoints;
	}>({});
	const [labels, setLabels] = useState<string[]>([]);
	const [itemData, setItemData] = useState<any>(item);
	const handleEdit = (index: number, postureId: string) => {
		setEditedPostures({
			...editedPostures,
			[postureId]: {
				...itemData[index].results.find(
					(p: { id: string }) => p.id === postureId,
				),
			},
		});
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const showModal = (src) => {
		setSelectedImage(src);
		setModalOpen(true);
	};

	const handleCancelModalLightBox = () => {
		setModalOpen(false);
		setSelectedImage(null);
	};

	const categories = [
		{ name: "Optimal Mobility", range: "90 - 100", color: "#52c41a" },
		{ name: "Functional Mobility", range: "75 - 89", color: "#91caff" },
		{ name: "Moderate Restriction", range: "60 - 74", color: "#faad14" },
		{ name: "Restricted Mobility", range: "40 - 59", color: "#f56c6c" },
		{ name: "Severely Restricted", range: "20 - 39", color: "#d9363e" },
		{ name: "Critically Impaired", range: "Below 20", color: "#7a7a7a" },
	];

	const dataSource = [
		// Optimal Mobility (90 - 100)
		{
			key: "1",
			screenshot: "https://placehold.co/1134x638",
			measure: "Right Shoulder Flexion",
			normal: 180,
			wfl: 170,
			value: 180,
			score: "100%",
			ranking: 9,
		},
		{
			key: "2",
			screenshot: "https://placehold.co/1134x638",
			measure: "Left Shoulder Flexion",
			normal: 180,
			wfl: 170,
			value: 172,
			score: "96%",
			ranking: 10,
		},
		{
			key: "3",
			screenshot: "https://placehold.co/1134x638",
			measure: "Right Hip Abduction",
			normal: 180,
			wfl: 170,
			value: 165,
			score: "92%",
			ranking: 9,
		},

		// Functional Mobility (75 - 89)
		{
			key: "4",
			screenshot: "https://placehold.co/1134x638",
			measure: "Left Knee Extension",
			normal: 180,
			wfl: 170,
			value: 150,
			score: "83%",
			ranking: 8,
		},
		{
			key: "5",
			screenshot: "https://placehold.co/1134x638",
			measure: "Right Ankle Dorsiflexion",
			normal: 180,
			wfl: 170,
			value: 140,
			score: "78%",
			ranking: 7,
		},

		// Moderate Restriction (60 - 74)
		{
			key: "6",
			screenshot: "https://placehold.co/1134x638",
			measure: "Left Hip Abduction",
			normal: 180,
			wfl: 170,
			value: 120,
			score: "67%",
			ranking: 6,
		},
		{
			key: "7",
			screenshot: "https://placehold.co/1134x638",
			measure: "Right Knee Flexion",
			normal: 180,
			wfl: 170,
			value: 115,
			score: "64%",
			ranking: 6,
		},

		// Restricted Mobility (40 - 59)
		{
			key: "8",
			screenshot: "https://placehold.co/1134x638",
			measure: "Left Shoulder Abduction",
			normal: 180,
			wfl: 170,
			value: 90,
			score: "50%",
			ranking: 5,
		},
		{
			key: "9",
			screenshot: "https://placehold.co/1134x638",
			measure: "Right Wrist Extension",
			normal: 180,
			wfl: 170,
			value: 85,
			score: "47%",
			ranking: 4,
		},

		// Severely Restricted (20 - 39)
		{
			key: "10",
			screenshot: "https://placehold.co/1134x638",
			measure: "Left Ankle Plantarflexion",
			normal: 180,
			wfl: 170,
			value: 60,
			score: "33%",
			ranking: 3,
		},

		// Critically Impaired (Below 20)
		{
			key: "11",
			screenshot: "https://placehold.co/1134x638",
			measure: "Right Elbow Extension",
			normal: 180,
			wfl: 170,
			value: 20,
			score: "11%",
			ranking: 1,
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "screenshot",
			key: "screenshot",
			width: "40px",
			render: (src) => (
				<PictureOutlined style={{ fontSize: 22, cursor: "pointer", color: "#999" }} onClick={() => showModal(src)} />
			),
		},
		{
			title: "Measure",
			dataIndex: "measure",
			key: "measure",
			width: "100%",
			onCell: () => ({ style: { flex: 1 } }),
		},
		{
			title: (
				<Popover content="Standard range of motion values." title="Normal">
					<Flex align='space-between' gap="small">
						<text>Normal</text><InfoCircleOutlined style={{ color: "#999", cursor: "pointer" }} />
					</Flex>
				</Popover>
			),
			dataIndex: "normal",
			key: "normal",
			align: "center",
			render: (normal) => { return <Text type="secondary">{normal}</Text> }
		},
		{
			title: (
				<Popover content="Values calibrated to OSHA standards." title="Within Functional Limits.">
					<Flex align='space-between' gap="small">
						<text>WFL</text><InfoCircleOutlined style={{ color: "#999", cursor: "pointer" }} />
					</Flex>
				</Popover>
			),
			dataIndex: "wfl",
			key: "wfl",
			align: "center",
			render: (wfl) => { return <Text type="secondary">{wfl}</Text> }
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
			align: "center",
			render: (value, record) => {
				const scoreNumber = parseInt(record.score.replace("%", ""));
				const category = categories.find(({ range }) => {
					const match = range.match(/\d+/g);
					const minScore = match ? (match.length === 2 ? parseInt(match[0]) : 0) : 0;
					const maxScore = match ? (match.length === 2 ? parseInt(match[1]) : parseInt(match[0])) : 100;
					return scoreNumber >= minScore && scoreNumber <= maxScore;
				});

				return <span style={{ color: category ? category.color : "inherit", fontWeight: "bold" }}>{value}</span>;
			},
		},
		{
			title: "Ranking",
			dataIndex: "ranking",
			key: "ranking",
			align: "center",
			render: (ranking, record) => {
				const scoreNumber = parseInt(record.score.replace("%", ""));
				const category = categories.find(({ range }) => {
					const match = range.match(/\d+/g);
					const minScore = match ? (match.length === 2 ? parseInt(match[0]) : 0) : 0;
					const maxScore = match ? (match.length === 2 ? parseInt(match[1]) : parseInt(match[0])) : 100;
					return scoreNumber >= minScore && scoreNumber <= maxScore;
				});

				return <span style={{ color: category ? category.color : "inherit", fontWeight: "bold" }}>{ranking}</span>;
				//   return <Rate allowHalf defaultValue={2.5} />;
			},
		},
		{
			title: "Score",
			dataIndex: "score",
			key: "score",
			align: "center",
			render: (score, record) => {
				const scoreNumber = parseInt(score.replace("%", ""));
				const category = categories.find(({ range }) => {
					const match = range.match(/\d+/g);
					const minScore = match ? (match.length === 2 ? parseInt(match[0]) : 0) : 0;
					const maxScore = match ? (match.length === 2 ? parseInt(match[1]) : parseInt(match[0])) : 100;
					return scoreNumber >= minScore && scoreNumber <= maxScore;
				});

				return <Tag style={{ marginInlineEnd: 0, width: "100%", textAlign: "center" }} color={category ? category.color : "default"}>{score}</Tag>;
			},
		},

		// {
		//   title: "Edit",
		//   key: "edit",
		//   width: "60px",
		//   render: () => <Button type="text" icon={<EditOutlined />} />,
		// },
	];


	useEffect(() => {
		const uniqueItems = new Map();
		item.forEach((exercise) => {
			const title = exercise?.title || "Unknown";
			if (!uniqueItems.has(title) || new Date(exercise.updatedAt) > new Date(uniqueItems.get(title).updatedAt)) {
				uniqueItems.set(title, exercise);
			}
		});
		setItemData(Array.from(uniqueItems.values()));
	}, [item]);

	const handleCancel = (postureId: string) => {
		const newEditedPostures = { ...editedPostures };
		delete newEditedPostures[postureId];
		setEditedPostures(newEditedPostures);
	};
	const dispatch = useTypedDispatch();
	const handleSave = async (postureId: string) => {
		const payload = {
			normal: editedPostures[postureId].normal,
			wfl: editedPostures[postureId].wfl,
			min: editedPostures[postureId].min,
			max: editedPostures[postureId].max,
			value: editedPostures[postureId].value,
		};
		await dispatch(
			patchRomBodyPoints({
				bodyPointId: editedPostures[postureId].id,
				payload: payload,
			}),
		);
		handleCancel(postureId);
		const updatedItems = itemData.map((exercise: ICustomRomExercise) => {
			if (exercise.results) {
				return {
					...exercise,
					results: exercise.results.map((bodyPoint: ICustomRomBodyPoints) =>
						bodyPoint.id === postureId
							? { ...bodyPoint, ...editedPostures[postureId] }
							: bodyPoint,
					),
				};
			}
			return exercise;
		});
		setItemData(updatedItems);
		message.success(t('Patient.data.omnirom.updateSuccess'));
	};

	const handleInputChange = (
		postureId: string,
		field: string,
		value: number,
	) => {
		setEditedPostures(prevState => ({
			...prevState,
			[postureId]: {
				...prevState[postureId],
				[field]: value,
			},
		}));
	};

	const calculateScore = (
		normal: number,
		value: number,
		id: number,
	): number => {
		if (id !== 21 && id !== 17 && id !== 4 && id !== 11) {
			if (value === 0) {
				return 0;
			}
			const score = Math.round((value / normal) * 100);
			return isFinite(score) ? score : 0;
		} else {
			if (value === 0) {
				return 100;
			}
			const score = Math.round(100 - (value / 100) * 100);
			return isFinite(score) ? score : 0;
		}
	};

	const handleRender = (scrnshot: string) => {
		return (
			<Image
				width={64}
				height={53}
				src={scrnshot}
				preview={{
					src: scrnshot,
					mask: <MdOutlineRemoveRedEye size={18} />,
					width: 'auto',
					height: 'auto',
				}}
				style={{ borderRadius: '0.5rem' }}
				onError={e => {
					const target = e.target as HTMLImageElement;
					target.src = '/images/white-image.png';
				}}
			/>
		);
	};


	const calculateRanking = (score: number): number => {
		if (score >= 91) return 10;
		if (score >= 81) return 9;
		if (score >= 71) return 8;
		if (score >= 61) return 7;
		if (score >= 51) return 6;
		if (score >= 41) return 5;
		if (score >= 31) return 4;
		if (score >= 21) return 3;
		if (score >= 11) return 2;
		if (score >= 1) return 1;
		return 0;
	};

	const rankingColor = (ranking: number) => {
		if (ranking >= 1 && ranking <= 6) return '#F04438';
		if (ranking >= 7 && ranking <= 9) return '#FDB022';
		if (ranking === 10) return '#3CCB7F';
		return 'gray';
	};

	useEffect(() => {
		if (user?.isPhysioterapist) {
			setLabels([
				'normal',
				'wfl',
				'value',
				'score',
				'ranking',
				'edit',
			]);
		} else {
			setLabels(['normal', 'wfl', 'value', 'score', 'ranking']);
		}
	}, [user]);

	return (
		<div className="rom-card-container p-0">
			{itemData?.map((exercise: ICustomRomExercise, idx: number) => (
				<>
					{exercise?.results.length > 0 ? (
						<Card
							key={exercise.id}
							className="MCardResults posture-card-section"
							size="small"
							title={
								<div className="posture-title-container">
									<p
										className="posture-title"
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '10px',
										}}>
										{exercise?.results.length > 1 &&
											handleRender(exercise?.screenshot)}
										<p>
											{(
												exercise?.title ||
												exercise?.strapiOmniRomExercise?.name ||
												exercise?.exerciseLibrary?.title ||
												exercise?.romProgramExercise?.strapiOmniRomExercise
													?.name ||
												exercise?.romProgramExercise?.exerciseLibrary?.title ||
												exercise?.results[0]?.romProgramExercise
													?.exerciseLibrary?.title ||
												exercise?.romProgramExercise?.exerciseLibrary?.title ||
												exercise?.results[0]?.romProgramExercise
													?.strapiOmniRomExercise?.name ||
												exercise?.results[0]?.strapiOmniRomExercise?.name ||
												'No Title'
											).toUpperCase()}
										</p>
									</p>
									<div
										className="posture-label-container"
										style={{
											width: user.isPhysioterapist ? '500px' : '415px',
											paddingLeft: '5px',
										}}>
										{labels.map(label => (
											<div key={label} className="posture-label">
												<p
													className="posture-label-text"
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
													}}>
													{label === 'wfl'
														? label.toUpperCase()
														: label.charAt(0).toUpperCase() +
														label.slice(1).toLowerCase()}
													{(label === 'wfl' || label === 'normal') && (
														<Tooltip
															placement="topLeft"
															title={
																<span className="text-gray-700 font-semibold !p-2">
																	{label === 'wfl'
																		? t(
																			'Patient.data.myProgress.omniRom.wflToolTip',
																		)
																		: t(
																			'Patient.data.myProgress.omniRom.normalToolTip',
																		)}
																</span>
															}
															color="white"
															className="text-gray-700 font-semibold">
															<div className="ml-1">
																<InfoCircle
																	width={16}
																	height={16}
																	color="stroke-gray-600"
																/>
															</div>
														</Tooltip>
													)}
												</p>
											</div>
										))}
									</div>
								</div>
							}>
							<List>
								{exercise.results?.map((posture: ICustomRomBodyPoints) => {
									const score = calculateScore(
										posture.normal || posture.romProgramExercise.normal,
										posture.value,
										posture.romProgramExercise.strapiOmniRomExerciseId,
									);
									const ranking = calculateRanking(score);

									return (
										<div key={posture.id} className="posture-sides-container">
											<div className="posture-sides-section">
												{exercise?.results.length <= 1 ? (
													handleRender(exercise?.screenshot)
												) : (
													<p className="posture-sides-title">
														{posture?.strapiOmniRomExercise?.name ||
															posture?.romProgramExercise?.strapiOmniRomExercise
																?.name ||
															posture?.romProgramExercise?.exerciseLibrary
																?.title ||
															posture?.strapiOmniRomExercise?.name ||
															posture?.exerciseLibrary?.title ||
															'Standard Motion'}
													</p>
												)}
											</div>
											<div className="posture-side-labels-container">
												{labels.map(label => {
													if (label === 'edit') {
														return (
															<div
																key={label}
																className="posture-side-labels-section">
																{editedPostures[posture.id] ? (
																	<div className="flex gap-2">
																		<Tooltip title="Cancel">
																			<div
																				style={{ cursor: 'pointer' }}
																				onClick={() =>
																					handleCancel(posture.id)
																				}>
																				<XClose color="stroke-rose-500" />
																			</div>
																		</Tooltip>
																		<Tooltip title="Save">
																			<div
																				style={{ cursor: 'pointer' }}
																				onClick={() => handleSave(posture.id)}>
																				<Check color="stroke-green-500" />
																			</div>
																		</Tooltip>
																	</div>
																) : (
																	<Tooltip title="Edit">
																		<div
																			style={{ cursor: 'pointer' }}
																			onClick={() =>
																				handleEdit(idx, posture.id)
																			}>
																			<Edit01 color="stroke-gray-400" />
																		</div>
																	</Tooltip>
																)}
															</div>
														);
													}
													const scoreColor = () => {
														if (score >= 90) return '#32D583';
														if (score >= 60 && score < 90) return '#FDB022';
														if (score >= 0 && score < 60) return '#F04438';
														return 'gray';
													};
													return (
														<div
															key={label}
															className="posture-side-labels-section">
															{editedPostures[posture.id] ? (
																label === 'score' || label === 'ranking' ? (
																	<p>
																		{label === 'score' ? (
																			<Progress
																				type="circle"
																				percent={score || 0}
																				strokeColor={scoreColor()}
																				width={50}
																				format={percent => (
																					<span
																						style={{
																							color: scoreColor(),
																							fontWeight: 'bold',
																						}}>
																						{percent}%
																					</span>
																				)}
																			/>
																		) : label === 'ranking' ? (
																			<span
																				style={{
																					color: rankingColor(ranking),
																				}}>
																				{ranking}
																			</span>
																		) : label === 'value' ? (
																			<span style={{ color: scoreColor() }}>
																				{posture[label]}
																			</span>
																		) : (
																			posture[label] || 0
																		)}
																	</p>
																) : (
																	<Input
																		value={
																			editedPostures[posture.id]?.[label] || 0
																		}
																		type="number"
																		style={{ width: '70px' }}
																		onChange={e =>
																			handleInputChange(
																				posture.id,
																				label,
																				Number(e.target.value),
																			)
																		}
																	/>
																)
															) : (
																<p>
																	{label === 'score' ? (
																		<Progress
																			type="circle"
																			percent={score || 0}
																			strokeColor={scoreColor()}
																			width={50}
																			format={percent => (
																				<span
																					style={{
																						color: scoreColor(),
																						fontWeight: 'bold',
																					}}>
																					{percent}%
																				</span>
																			)}
																		/>
																	) : label === 'ranking' ? (
																		<span
																			style={{ color: rankingColor(ranking) }}>
																			{ranking}
																		</span>
																	) : label === 'value' ? (
																		<span style={{ color: scoreColor() }}>
																			{posture[label]}
																		</span>
																	) : (
																		posture[label] || 0
																	)}
																</p>
															)}
														</div>
													);
												})}
											</div>
										</div>
									);
								})}
							</List>
						</Card>
					) : (
						<>
							<div className="coach-rom-summary-container-row-div">
								<img
									style={{ width: 100, height: 100 }}
									className="coach-rom-summary-container-row-div-img"
									src="/images/NoExe.png"
									alt="No Exercises Image"
								/>
								<h1 className="coach-rom-summary-container-row-div-h1">
									{t('Patient.data.myProgress.omniRom.empty')}
								</h1>
							</div>
						</>
					)}
				</>
			))}
		</div>
	);
};

export default CustomSummaryContentResult;
