import { MagicWand01 } from '@carespace-icons/editor/magicWand01';
import { Compass } from '@carespace-icons/education/compass';
import { Ruler } from '@carespace-icons/education/ruler';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.css';
import { useEffect, useState } from 'react';
import CreateRomModal from '@pages/AiAssistantStartScan/OCreateRomModal';
import { resetAll, setOmniromUpload } from '@stores/rehab/main';
import CustomOptionsList from './CustomOptionsList';
import './style.css';
import { router } from '@routers/routers';

export default function AiAssistantStartScan() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);
	const dispatch = useTypedDispatch();
	const [openRomPlaylist, setOpenRomPlaylist] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [isLoading, setLoading] = useState<boolean>(true);
	const { state } = useLocation();
	const [activeKey, setActiveKey] = useState<string>('1');
	const [subActiveKey, setSubActiveKey] = useState<string>('1');

	const buttonStyle = {
		color: "var(--button-text-color)",
		border: 'inherit',
		marginTop: '-5px',
		width: '80%',
	};
	const isIpad =
		/iPad|Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;

	useEffect(() => {
		if (!sessionStorage.getItem('freshEntry') && isIpad) {
			sessionStorage.setItem('freshEntry', 'true');
			console.log('Reloading page to clean up memory...');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} else {
			sessionStorage.removeItem('freshEntry');
		}
	}, []);

	useEffect(() => {
		dispatch(setOmniromUpload(false));
		setSubActiveKey('1');
	}, []);
	useEffect(() => {
		dispatch(setOmniromUpload(false));
		setSubActiveKey('1');
	}, []);

	useEffect(() => {
		if (state?.showPopup) {
			setOpenRomPlaylist(true);
			setSubActiveKey('2');
		}
	}, [state]);

	useEffect(() => {
		if (state?.showPopup) {
			setOpenRomPlaylist(true);
			setSubActiveKey('2');
		}
	}, [state]);


	useEffect(() => {
		dispatch(setOmniromUpload(false));
		setSubActiveKey('1');
	}, []);

	useEffect(() => {
		if (state?.showPopup) {
			setOpenRomPlaylist(true);
			setSubActiveKey('2');
		}
	}, [state]);

	const scanCards = [
		{
			image: '/images/dashboard/omnirom-dashboard.png',
			logo: '/images/dashboard/omnirom-logo-white.svg',
			text: t('Patient.data.postures.romMeasures'),
			buttonText: t('Patient.data.dashboardScreen.mobilityScore'),
			onClick: () => {
				dispatch(resetAll());
				navigate(
					`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_START_ROM_SCAN}`,
				);
			},
		},
		{
			image: '/images/dashboard/posture-woman-banner.png',
			logo: '/images/dashboard/posture-analysis.svg',
			text: t('Patient.data.postures.postureMeasures'),
			buttonText: t('Patient.data.dashboardScreen.startPostureScan'),
			onClick: () =>
				navigate(
					`/${user?.isPhysioterapist ? selectedUser?.id : user?.id}${router.AIASSISTANT_POSTURE_SCAN}`,
				),
		},
		{
			image: '/images/dashboard/rom.png',
			logo: '/images/dashboard/omnirom-logo-white.svg',
			text: t('Patient.data.postures.customMeasures'),
			buttonText: t('Patient.data.dashboardScreen.createCustomScan'),
			onClick: () => {
				setOpenRomPlaylist(true);
				setActiveKey('1');
				setSubActiveKey('1');
			},
		},
	];

	const visibleCards = user?.isPhysioterapist
		? scanCards
		: scanCards.slice(0, 2);
	const containerClass =
		visibleCards.length === 3 ? 'three-cards' : 'two-cards';

	return (
		<div className="m-6 pb-10 start-scan">
				<CreateRomModal
				isVisible={openRomPlaylist}
				onCancel={() => setOpenRomPlaylist(false)}
				refresh={refresh}
				setRefresh={setRefresh}
				setSearchValue={setSearchValue}
				searchValue={searchValue!}
				activeKey={activeKey}
				setActiveKey={setActiveKey}
				subActiveKey={subActiveKey}
				setSubActiveKey={setSubActiveKey}
			/>
			<div className={`start-scan-cards-container ${containerClass}`}>
				{visibleCards.map((card, index) => (
					<div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
						<div
							key={index}
							style={{ background: `url(${card.image}) center top / contain no-repeat` }}
							className="start-scan-card-background">
							<div className="start-scan-card-content-container">
								<img src={card.logo} alt="Logo" />
								<p className="start-scan-card-content">{card.text}</p>
								<Button
									style={buttonStyle}
									className="start-session-css"
									onClick={card.onClick}>
									<p
										style={{
											width: '100%',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											textAlign: 'center',
										}}>
										{card.buttonText}
									</p>
								</Button>
							</div>
						</div>
					</div>
				))}
			</div>
			<CustomOptionsList
				isLoading={isLoading}
				refresh={refresh}
				setRefresh={setRefresh}
				setLoading={setLoading}
			/>
		</div>
	);
}
