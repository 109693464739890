import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	ICustomRom,
	ICustomRomExercise,
	ICustomRomSession,
	ICustomRomState,
	ICustonRomPaginated,
} from '@stores/interfaces';
import { message } from 'antd';
import axios from 'axios';
import html2canvas from 'html2canvas';

export const getPrintScreen = async () => {
	const canvas = await html2canvas(document.getElementById('printscreen')!);
	return canvas.toDataURL();
};

export const getRomTemplates = createAsyncThunk(
	'getRomTemplates',
	async ({
		page,
		search,
	}: {
		page: number;
		search: string;
	}): Promise<ICustonRomPaginated> => {
		try {
			const { data } = await axios.get(
				`/rom/program-templates?limit=10&page=${page}${search === '' ? '' : `&search=${search}`}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const patchRomBodyPoints = createAsyncThunk(
	'patchRomBodyPoints',
	async ({
		bodyPointId,
		payload,
	}: {
		bodyPointId: string;
		payload: any;
	}): Promise<ICustomRomExercise> => {
		try {
			const { data } = await axios.patch(
				`/rom/sessions/patient-results/${bodyPointId}`,
				payload,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getCustomRomList = createAsyncThunk(
	'getCustomRomList',
	async ({
		userId,
		page,
		limit,
		search = '',
	}: {
		userId: string;
		page: number;
		limit: number;
		search?: string;
	}): Promise<ICustonRomPaginated> => {
		try {
			const { data } = await axios.get(
				`/rom/programs/patients/${userId}?limit=${limit}&page=${page}${search === '' ? '' : `&search=${search}`}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getCustomRomSession = createAsyncThunk(
	'getCustomRomSession',
	async ({
		userId,
		page,
	}: {
		userId: string;
		page: number;
		search?: string;
	}): Promise<ICustonRomPaginated> => {
		try {
			const { data } = await axios.get(
				`/rom/sessions/${userId}?limit=10&page=${page}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getCustomRomSessionById = createAsyncThunk(
	'getCustomRomSessionById',
	async ({
		customRomId,
		page,
	}: {
		customRomId: string;
		page: number;
	}): Promise<ICustomRomSession> => {
		try {
			const { data } = await axios.get(
				`/rom/sessions/programs/${customRomId}?limit=10&page=${page}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getRomSessionByIdforActivity = createAsyncThunk(
	'getRomSessionByIdforActivity',
	async ({
		customRomId,
	}: {
		customRomId: string;
	}): Promise<ICustomRomSession> => {
		try {
			const { data } = await axios.get(`/rom/session/${customRomId}`);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const updateCustomRom = createAsyncThunk(
	'updateCustomRom',
	async (payload: any): Promise<ICustomRom> => {
		try {
			const { programId, programData } = payload;
			const { data } = await axios.patch(
				`/rom/programs/${programId}`,
				programData,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const postRomTemplates = createAsyncThunk(
	'postRomTemplates',
	async (payload: any): Promise<ICustonRomPaginated> => {
		try {
			const { data } = await axios.post(`/rom/program-templates`, payload);
			return data;
		} catch (error) {
			error?.response?.data.statusCode === 409
				? message.error(error?.response?.data.message)
				: message.error('Something Went Wrong !');

			throw error;
		}
	},
);

export const createCustomRomSession = createAsyncThunk(
	'createCustomRomSession',
	async (payload: any): Promise<ICustomRomSession> => {
		try {
			const { data } = await axios.post(`/custom-rom/session`, payload);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const saveExercise = createAsyncThunk(
	'saveExercise',
	async (payload: any): Promise<ICustomRomExercise> => {
		try {
			const { data } = await axios.post(
				`/custom-rom/session/exercises`,
				payload,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const updateSessionExercise = createAsyncThunk(
	'updateSessionExercise',
	async (payload: {
		exerciseId: string;
		exerciseData: any;
	}): Promise<ICustomRomExercise> => {
		try {
			const { exerciseId, exerciseData } = payload;
			const { data } = await axios.patch(
				`/custom-rom/session/exercises/${exerciseId}`,
				exerciseData,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const closeSession = createAsyncThunk(
	'closeSession',
	async (sessionId: string): Promise<ICustomRomSession> => {
		try {
			const { data } = await axios.patch(`/custom-rom/session/${sessionId}`);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export enum ETransitions {
	INTRO = 'intro',
	CALIBRATION = 'calibration',
	READYSETGO = 'readySetGo',
	RESULT = 'result',
	OPENNING = 'openning',
}

export type TTransitions = {
	next: TTransitions | null;
	value: ETransitions;
};

export const linkedListTransitions = () => {
	const transitions = Object.values(ETransitions).reduceRight(
		(next: TTransitions | null, value) => ({ value, next }),
		null,
	);

	if (transitions && transitions.value !== ETransitions.INTRO) {
		return { value: ETransitions.INTRO, next: transitions };
	}

	return transitions;
};

const initialState: ICustomRomState = {
	customRom: {
		data: [],
		pagination: null,
	},
	customRomSessionData: {
		data: [],
		pagination: null,
	},
	customRomSession: null,
	data: [],
	pagination: null,
	romUploadDetails: null,
	selectedRom: null,
};

const customRom = createSlice({
	name: 'romTemplates',
	initialState,
	reducers: {
		setRomUploadDetails: (state, action) => {
			state.romUploadDetails = action.payload;
		},
		setSelectedRom: (state, action) => {
			state.selectedRom = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(getRomTemplates.fulfilled, (state, action) => {
			state.data = action?.payload?.data;
			state.pagination = action?.payload?.pagination;
		});
		builder.addCase(getRomTemplates.rejected, (state, action) => { });
		builder.addCase(postRomTemplates.fulfilled, (state, action) => {
			state.data = action?.payload?.data;
			state.pagination = action?.payload?.pagination;
		});
		builder.addCase(postRomTemplates.rejected, (state, action) => { });

		builder.addCase(getCustomRomList.fulfilled, (state, action) => {
			state.customRom.data = action.payload.data;
			state.customRom.pagination = action.payload.pagination;
		});
		builder.addCase(getCustomRomList.rejected, (state, action) => { });

		builder.addCase(getCustomRomSession.fulfilled, (state, action) => {
			state.customRomSessionData.data = action.payload.data;
			state.customRomSessionData.pagination = action.payload.pagination;
		});
		builder.addCase(getCustomRomSession.rejected, (state, action) => { });

		builder.addCase(updateCustomRom.fulfilled, (state, action) => { });
		builder.addCase(updateCustomRom.rejected, (state, action) => { });

		builder.addCase(createCustomRomSession.fulfilled, (state, action) => {
			state.customRomSession = action.payload;
		});
		builder.addCase(createCustomRomSession.rejected, (state, action) => { });

		builder.addCase(saveExercise.fulfilled, (state, action) => { });
		builder.addCase(saveExercise.rejected, (state, action) => { });

		builder.addCase(updateSessionExercise.fulfilled, (state, action) => { });
		builder.addCase(updateSessionExercise.rejected, (state, action) => { });

		builder.addCase(closeSession.fulfilled, (state, action) => { });
		builder.addCase(closeSession.rejected, (state, action) => { });
	},
});

export const { setRomUploadDetails, setSelectedRom } = customRom.actions;

export default customRom.reducer;
