import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IReportData } from '@stores/interfaces';
import axios from 'axios';

type ReportIdTypes =
	| 'romSessionsIds'
	| 'romResultsIds'
	| 'postureSessionsIds'
	| 'programSessionsIds'
	| 'surveyResultIds'
	| 'evaluationSessionsIds'
	| 'exercisesSelectedRows';

export const getUserReports = createAsyncThunk(
	'getUserReports',
	async (payload: any): Promise<any> => {
		try {
			const { userId, limit, page, search = '', sort } = payload;
			const { data } = await axios.get(
				`/reports/users/${userId}?limit=${limit}&page=${page}&sort=${sort}&${search === '' ? '' : `search=${search}`}`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const createReport = createAsyncThunk(
	'createReport',
	async (payload: any): Promise<any> => {
		try {
			const { data } = await axios.post(`/reports/create`, payload);
			return data;
		} catch (error: any) {
			error?.response?.data.statusCode === 409
				? message.error(error?.response?.data.message)
				: message.error('Something Went Wrong !');
		}
	},
);

export const createSelectedReport = createAsyncThunk(
	'createSelectedReport',
	async (payload: any): Promise<any> => {
		try {
			const { data } = await axios.post(`/reports`, payload);
			return data;
		} catch (error: any) {
			error?.response?.data.statusCode === 409
				? message.error(error?.response?.data.message)
				: message.error('Something Went Wrong !');
		}
	},
);

export const updateReport = createAsyncThunk(
	'updateReport',
	async ({
		payload,
		reportId,
	}: {
		payload: any;
		reportId: string;
	}): Promise<any> => {
		try {
			const { data } = await axios.patch(`/reports/${reportId}`, payload);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const updateReportNotes = createAsyncThunk(
	'updateReportNotes',
	async ({
		payload,
		reportId,
	}: {
		payload: any;
		reportId: string;
	}): Promise<any> => {
		try {
			const { data } = await axios.patch(
				`/reports/notes/${reportId}`,
				payload,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getReport = createAsyncThunk(
	'getReport',
	async (reportId: string): Promise<any> => {
		try {
			const { data } = await axios.get(`/reports/${reportId}`);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const getReportCsv = createAsyncThunk(
	'getReportCsv',
	async (payload: { startDate: string; endDate: string }): Promise<any> => {
		try {
			const { startDate, endDate } = payload;
			const response = await axios.get(
				`/reports/aggregate/csv?startDate=${startDate}&endDate=${endDate}`,
				{
					responseType: 'blob',
				},
			);
			return response.data;
		} catch (error) {
			const errorMessage =
				error?.response?.data?.message || 'An unexpected error occurred';
			message.error(errorMessage);
			throw error;
		}
	},
);

const initialState: IReportData = {
	reports: {
		data: null,
		pagination: null,
	},
	report: null,
	loading: false,
	reportIds: {
		romSessionsIds: [],
		postureSessionsIds: [],
		romResultsIds: [],
		programSessionsIds: [],
		surveyResultIds: [],
		evaluationSessionsIds: [],
		exercisesSelectedRows: [],
	},
	searchTextForReports: '',
};

const reports = createSlice({
	name: 'userReports',
	initialState,
	reducers: {
		addReportId: (
			state,
			action: PayloadAction<{ type: ReportIdTypes; id: string }>,
		) => {
			state.reportIds[action.payload.type].push(action.payload.id);
		},
		deleteReportId: (
			state,
			action: PayloadAction<{ type: ReportIdTypes; id: string }>,
		) => {
			state.reportIds[action.payload.type] = state.reportIds[
				action.payload.type
			].filter((id: string) => id !== action.payload.id);
		},
		updateReportIds: (
			state,
			action: PayloadAction<{ type: ReportIdTypes; ids: string[] }>,
		) => {
			state.reportIds[action.payload.type] = action.payload.ids;
		},
		setSearchTextForReports: (state, action) => {
			state.searchTextForReports = action.payload;
		},
		clearReports: state => {
			state.reportIds.romSessionsIds = [];
			state.reportIds.romResultsIds = [];
			state.reportIds.postureSessionsIds = [];
			state.reportIds.programSessionsIds = [];
			state.reportIds.evaluationSessionsIds = [];
			state.reportIds.exercisesSelectedRows = [];
			state.reportIds.surveyResultIds = [];
		},
	},
	extraReducers: builder => {
		builder.addCase(getUserReports.fulfilled, (state, action) => {
			state.reports = action.payload;
		});
		builder.addCase(getUserReports.rejected, (state, action) => { });

		builder.addCase(getReport.fulfilled, (state, action) => {
			state.report = action.payload;
		});
		builder.addCase(getReport.rejected, (state, action) => { });

		builder.addCase(getReportCsv.fulfilled, (state, action) => {
			state.loading = false;
		});
		builder.addCase(getReportCsv.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(getReportCsv.rejected, (state, action) => {
			state.loading = false;
		});

		builder.addCase(updateReport.fulfilled, (state, action) => {
			state.report = action.payload;
		});
		builder.addCase(updateReport.rejected, (state, action) => { });

		builder.addCase(updateReportNotes.fulfilled, (state, action) => {
			state.report = action.payload;
		});
		builder.addCase(updateReportNotes.rejected, (state, action) => { });

		builder.addCase(createReport.fulfilled, (state, action) => {
			action.payload && message.success('Report Created Successfully!');
		});
		builder.addCase(createReport.rejected, (state, action) => { });
		builder.addCase(createSelectedReport.fulfilled, (state, action) => { });
		builder.addCase(createSelectedReport.rejected, (state, action) => { });
	},
});

export const {
	addReportId,
	setSearchTextForReports,
	clearReports,
	updateReportIds,
	deleteReportId,
} = reports.actions;

export default reports.reducer;
