import { Route, Routes } from 'react-router-dom';
import NewPatients from '@pages/NewPatients';
import OutOfParams from '@pages/OutOfParams';
import PendingReview from '@pages/PendingReview';
import Reviewed from '@pages/Reviewed';
import EscalationRequired from '@pages/EscalationRequired';
import FollowUpRequired from '@pages/FollowUpRequired';
import ActivityStream from '@pages/ActivityStream';
import UnAssignedPatients from '@pages/AdminUnassignedPatients';
import PendingInvites from '@pages/AdminPendingInvites';
import RegisteredPatients from '@pages/AdminRegisteredPatients';
import Layoult from '@molecules/MLayoult';
import { Program } from '@organisms/OProgram';
import AiAssistantMyReport from '@pages/AiAssistantMyReport';
import { ListEvaluation } from '@pages/AiAssistant/Conversation/ListEvaluation';
import AiAssistantRomSummary from '@pages/AiAssistantRomSummary';
import { LetsMoveSessions } from '@pages/AiAssistant/Conversation/LetsMoveSessions';
import AiAssistantSurveySummary from '@pages/AiAssistantSurveySummary';
import AiAssistantStartScan from '@pages/AiAssistantStartScan';
import AiAssistantActivity from '@pages/AiAssistantActivity';
import ANoComponentFound from '@atoms/ANoComponentFound/ANoComponentFound';
import AiVirtualAssessment from '@pages/AiVirtualAssessment';
import AiAssistantCreateReportModal from '@pages/AiAssistantCreateReport';
import { AiAssistantStartSurvey } from '@pages/AiAssistantStartSurvey';
import { StartSurveyUser } from '@pages/AiAssistantStartSurvey/StartSurveyUser';
import { Programs } from '@pages/AIAssistantProgram';
import PostureScan from '@pages/PostureScan';
import AiPostureSummary from '@pages/AiPosture/AiPostureSummary';
import { PostureCaptures } from '@pages/AiAssistantPostureCaptures';
import { CustomCaptures } from '@pages/AiAssistantCustomCaptures';
import OmniRomAddExercise from '@organisms/ORehab/RecordUploadExercises/OmniRomAddExercise';
import EmptyStatePage from '@pages/EmptyStatePage/EmptyStatePage';
import EmptyActivityPage from '@pages/EmptyStatePage/EmptyActivtyPage';
import CoachRom from '@pages/Rom';
import { AiAssistant } from '@pages/AiAssistant';
import loadable from '@loadable/component';
import Loading from '@atoms/Loading';
import { router } from '@routers/routers';
import DashboardInviteCode from '@pages/DashboardInviteCode/DashboardInviteCode';
import AiAssistantCustomRomScanResult from '@pages/AiAssistantCustomRomScanResult';

const opts = {
	fallback: <Loading />,
};

const Rehab = loadable(() => import('../components/pages/Rehab'), opts);

const Admin = () => (
	<Routes>
		<Route path={router.ROOT} element={<Layoult />}>
			<Route index element={<NewPatients />} />
			<Route path={router.NEW_PATIENTS} element={<NewPatients />} />
			<Route path={router.OUTOFPARAMETERS} element={<OutOfParams />} />
			<Route path={router.PENDINGREVIEW} element={<PendingReview />} />
			<Route path={router.REVIEWED} element={<Reviewed />} />
			<Route path={router.ESCALATIONREQUIRED} element={<EscalationRequired />} />
			<Route path={router.FOLLOWUPREQUIRED} element={<FollowUpRequired />} />
			<Route path={router.ACTIVITY} element={<ActivityStream />} />
			<Route path={router.UNASSIGNEDPATIENTS} element={<UnAssignedPatients />} />
			<Route path={router.PENDINGINVITES} element={<PendingInvites />} />
			<Route path={router.REGISTEREDPATIENTS} element={<RegisteredPatients />} />
			<Route path={router.SELECTUSER} element={<EmptyStatePage />} />
			<Route path={router.SELECTACTIVITYUSER} element={<EmptyActivityPage />} />
			<Route path={router.ROOT} element={<AiAssistant />}>
				<Route path={router.PROGRAMADDEXERCISES} element={<Rehab />} />
				<Route path={router.USERACTIVITY} element={<AiAssistantActivity />} />
				<Route path={router.OMNIROMADDEXERCISES} element={<OmniRomAddExercise />} />
				<Route path={router.USERREPORTSUMMARY} element={<AiAssistantMyReport />} />
				<Route path={router.USERVIRTUALEVALUATION} element={<AiVirtualAssessment />} />
				<Route path={router.USERVIRTUALEVALUATIONRESULT} element={<ListEvaluation />} />
				<Route path={router.USERROMSUMMARY} element={<AiAssistantRomSummary />} />
				<Route path={router.USERPROGRAMSTART} element={<Program />} />
				<Route path={router.USERPAGENOTFOUND} element={<ANoComponentFound />} />
				<Route path={router.USERROMSCAN} element={<AiAssistantStartScan />} />
				<Route path={router.USERROMSTARTSCAN} element={<CoachRom />} />
				<Route path={router.USERROMCAPTURES} element={<CustomCaptures />} />
				<Route path={router.POSTURE_ANALYTICS_SCAN} element={<PostureScan />} />
				<Route path={router.POSTURE_ANALYTICS_SUMMARY} element={<AiPostureSummary />} />
				<Route path={router.POSTURE_ANALYTICS_CAPTURES} element={<PostureCaptures />} />
				<Route path={router.PROGRAM_GENERATE} element={<Programs />} />
				<Route path={router.PROGRAM_CREATE} element={<Programs />} />
				<Route path={router.PROGRAM_SUMMARY} element={<LetsMoveSessions />} />
				<Route path={router.SURVEY_ASSIGN} element={<AiAssistantStartSurvey />} />
				<Route path={router.SURVEY_CREATE} element={<AiAssistantStartSurvey />} />
				<Route path={router.SURVEY_START} element={<StartSurveyUser />} />
				<Route path={router.SURVEY_SUMMARY} element={<AiAssistantSurveySummary />} />
				<Route path={router.REPORT_CREATE} element={<AiAssistantCreateReportModal />} />
				<Route path={router.USER_DOWNLOAD_APP} element={<DashboardInviteCode />} />
				<Route path={router.USERROMSCANRESULT} element={<AiAssistantCustomRomScanResult />} />
			</Route>
		</Route>
	</Routes>
);

export default Admin;
