import {
	Checkbox,
	Collapse,
	Dropdown,
	Empty,
	Flex,
	Menu,
	Pagination,
	Space,
	Spin,
	message,
	Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { getCustomRomSessionById, setSelectedRom } from '@stores/rom/customRom';
import {
	ICustomRomSession,
	ICustonRomSessionPaginated,
	IOnchangeStatus,
	IstatusIcon,
	Status,
} from '@stores/interfaces';
import { addReportId, deleteReportId } from '@stores/report/reports';
import CustomSummarySelectionContent from './CustomSummarySelectionContent';
import { useLocation, useNavigate } from 'react-router-dom';
import Badge from '@atoms/Badge';
import { StatusNormalized } from '@stores/constants';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusOutOfParams } from '@stores/outOfParams';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusPendingReview } from '@stores/pendingReview';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusReviewed } from '@stores/reviewed';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusEscalationRequired } from '@stores/escalationRequired';
import { updateOmniRomSessionStatus as updateOmniRomSessionStatusFollowUpRequired } from '@stores/followUpRequired';
import { router } from '@routers/routers';

interface ICustomSummarySession {
	session: string;
	programId?: string;
}

const { Panel } = Collapse;
const CustomSummarySession = (props: ICustomSummarySession) => {
	const [selectedCollapse, setSelectedCollapse] = useState<
		string[] | string | undefined
	>();
	const { session, programId } = props;
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();
	const [customRomSessionExercise, setCustomRomSessionExercise] =
		useState<ICustonRomSessionPaginated>();
	const [isLoading, setLoading] = useState(false);

	const fetchSessionData = async (sessionId: string, page: number) => {
		setLoading(true);
		const data = await dispatch(
			getCustomRomSessionById({ customRomId: sessionId, page: page }),
		);
		setCustomRomSessionExercise(data.payload);
		setLoading(false);
	};
	const state = useLocation();
	const navigate = useNavigate();
	const user = useTypedSelector(state => state.user);
	const { selectedUser } = useTypedSelector(state => state.contacts.main);

	const statusIcons: IstatusIcon = {
		outOfParams: <Badge color="black" />,
		pendingReview: <Badge color="yellow" />,
		reviewed: <Badge color="green" />,
		escalationRequired: <Badge color="rose" />,
		followUpRequired: <Badge color="orange" />,
	};

	type IUpdatePostureSessionStatus = Omit<Record<Status, any>, 'newPatients'>;

	const updateOmniRomStatus: IUpdatePostureSessionStatus = {
		outOfParams: updateOmniRomSessionStatusOutOfParams,
		pendingReview: updateOmniRomSessionStatusPendingReview,
		reviewed: updateOmniRomSessionStatusReviewed,
		escalationRequired: updateOmniRomSessionStatusEscalationRequired,
		followUpRequired: updateOmniRomSessionStatusFollowUpRequired,
	};

	const onChangeStatus = async (params: IOnchangeStatus, item: any) => {
		const { sessionId, status } = params;
		message.loading({
			content: t('Admin.data.menu.aiAssistant.aiAssistantRomSummary.saving'),
			key: sessionId,
		});
		await dispatch(
			updateOmniRomStatus[item.status as keyof IUpdatePostureSessionStatus]({
				sessionId,
				body: { status },
			}),
		);
		message.success({
			content: t(
				'Admin.data.menu.aiAssistant.aiAssistantRomSummary.statusSuccess',
			),
			key: sessionId,
			duration: 3,
		});
		const data = await dispatch(
			getCustomRomSessionById({
				customRomId: session,
				page: customRomSessionExercise?.pagination?.currentPage || 1,
			}),
		);
		setCustomRomSessionExercise(data.payload);
	};

	const onPageChange = async (pageNumber: number) => {
		setLoading(true);
		fetchSessionData(session, pageNumber);
		setLoading(false);
	};
	useEffect(() => {
		fetchSessionData(session, 1);
	}, [session]);

	const reportIds = useTypedSelector(state => state.reports.reportIds);

	const healthStatus = (session: { id: string; status: Status }) => {
		const menu = (
			<Menu>
				{Object.entries(StatusNormalized)
					.filter(([key]) => key !== session.status)
					.map(([status, label]) => {
						return (
							<Menu.Item
								key={status}
								onClick={() => {
									onChangeStatus(
										{ sessionId: session.id, status: status as Status },
										session,
									);
								}}>
								<Space>
									<span className="dropdown-badge">
										{statusIcons[status as keyof IstatusIcon]}
									</span>
									<span>{label}</span>
								</Space>
							</Menu.Item>
						);
					})}
			</Menu>
		);
		return (
			<div style={{ paddingRight: 0 , display: 'flex'}}>
				<div className="posture-dropdown-section">
					{user.isPhysioterapist ? (
						<Dropdown overlay={menu}>
							<a onClick={e => e.stopPropagation()}>
								{statusIcons[session.status as keyof IstatusIcon]}
							</a>
						</Dropdown>
					) : (
						''
					)}
				</div>
				<div
					style={{ paddingRight: 0 }}
					onClick={event => {
						event.stopPropagation();
					}}>
					<Checkbox
						checked={reportIds?.romSessionsIds?.includes(session.id)}
						disabled={session?.romPatientResults?.length <= 0}
						className="report-checkbox"
						onChange={e => {
							if (e.target.checked) {
								dispatch(
									addReportId({
										type: 'romSessionsIds',
										id: session.id,
									}),
								);
							} else {
								dispatch(
									deleteReportId({
										type: 'romSessionsIds',
										id: session.id,
									}),
								);
							}
						}}
					/>
				</div>
			</div>
		);
	};
	return (
		<>
			{isLoading ? (
				<Spin
					className="flex items-center justify-center p-2"
					tip="Loading"
					size="large"
				/>
			) : (
				<>
					{customRomSessionExercise?.data?.length! > 0 ? (
							<div className='select-none posture-collapse bg-gray-100'>{customRomSessionExercise?.data?.map((session: ICustomRomSession) => {
								return (
									<div className="header-panel !bg-white !border !border-gray-200 !rounded-lg my-1.5 p-3 cursor-pointer flex justify-between">
										<span onClick={e => {
											e.stopPropagation();
											dispatch(setSelectedRom(session));
											navigate(
												`/${user.isPhysioterapist ? selectedUser.id : user.id}${router.AIASSISTANT_ROM_SCAN_RESULT}`,
											);
										}}>{moment(session?.createdAt).local().format('LLLL')}</span>
										<span>{healthStatus(session)}</span>
									</div>
								)
							})}
							</div>
					) : (
						<div className="coach-rom-summary-container-row-div">
							<img
								style={{ width: 100, height: 100 }}
								className="coach-rom-summary-container-row-div-img"
								src="/images/NoExe.png"
								alt="No Exercises Image"
							/>
							<h1 className="coach-rom-summary-container-row-div-h1">
								{t('Patient.data.myProgress.omniRom.empty')}
							</h1>
						</div>
					)}
					{customRomSessionExercise?.pagination?.totalCount! >= 5 && (
						<Pagination
							className="flex justify-center"
							current={customRomSessionExercise?.pagination?.currentPage}
							showSizeChanger={false}
							onChange={onPageChange}
							total={customRomSessionExercise?.pagination?.totalCount}
						/>
					)}
				</>
			)}
		</>
	);
};

export default CustomSummarySession;
