import TableList from "@atoms/TableList"
import { useEffect, useState } from "react"
import { useTypedDispatch, useTypedSelector } from "@stores/index"
import { Badge, Pagination, Spin, Tag } from "antd"
import { useTranslation } from "react-i18next"
import { getNewPatients } from "@stores/newPatients"
import { INewUserData, IUserPaginationDefaultResponse } from "@stores/interfaces"
import './style.css';
import AToggleMenu from "@atoms/AToggleMenu"

const NewPatients = () => {
	const { t } = useTranslation();

	const TEAM_MEMBER_HEADER = [
		{ name: t('Admin.data.newPatients.name'), helperText: 'I am helper', sort: true, key: "name" },
		{ name: t('Admin.data.newPatients.status'), helperText: 'I am helper', sort: true, key: "status" },
		{ name: t('Admin.data.newPatients.date'), helperText: 'I am helper', sort: true, key: "date" }
	]


	const newPatientDataList = useTypedSelector((state) => state.newPatients)
	const [patientData, setPatientData] = useState<INewUserData[]>([]);
	const [pagination, setPagination] = useState<IUserPaginationDefaultResponse>(newPatientDataList?.pagination || {})
	const dispatch = useTypedDispatch()
	const [isLoading, setIsLoading] = useState(true)

	const onClickSort = (key: string) => {
		console.log('sort by', key)
	}

	const onPageChange = async (pageNumber: number) => {
		if (pageNumber) {
			const paginate = {
				...pagination,
				nextPage: pageNumber
			}
			dispatch(getNewPatients(paginate)).then(()=>setIsLoading(false))
		}
	}

	useEffect(() => {
		if (newPatientDataList?.data?.length > 0) {
			setPatientData(newPatientDataList?.data);
			setPagination(newPatientDataList?.pagination || {});
		}
	}, [newPatientDataList])

	useEffect(() => {
		onPageChange(1)
	}, [])

	return (
		<div className="total-patient-main-div">
			{isLoading ? <Spin className="spin-css" tip="Loading" size="large" /> :<>
			<h3 className="gap-2">
				<AToggleMenu />
				<span className="badge-css"><Badge color='#84caff' /></span>  {t('Admin.data.newPatients.newPatients')} <span className='total-patient-length'><Tag color="purple">{patientData.length} {t('Admin.data.newPatients.users')}</Tag></span>
			</h3>
			<TableList
				tableData={patientData}
				header={TEAM_MEMBER_HEADER}
				rowSelect={false}
				onSort={onClickSort}
			/>
			{
				pagination?.total! > 10 && <Pagination defaultCurrent={pagination?.currentPage}
					total={pagination?.total} onChange={onPageChange}
					style={{ textAlign: 'center' }} showSizeChanger={false} />
			}
			</>
}
		</div>
	)
}

export default NewPatients