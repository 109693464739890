import { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Dropdown, Menu, Popover, Space } from 'antd';
import { useTheme } from './ThemeContext';
import { FaCaretLeft } from 'react-icons/fa';
import { getTheme, postTheme } from '@stores/settings/setings';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import Cookies from 'js-cookie';
import CheckBox from '@atoms/CheckBox';
import { useTranslation } from 'react-i18next';
import { THEME } from '@stores/constants';

const themeColors = {
	vibrant: '#ff6600',
	default: '#6f42c1',
	dark: '#333333',
};

const themes = [
	{ name: THEME.DEFAULT, file: '/assets/default.svg', color: '#6f42c1' },
	{ name: THEME.VIBRANT, file: '/assets/vibrant.svg', color: '#ff6600' },
	{ name: THEME.DARK, file: '/assets/dark.svg', color: '#333333' },
];

const ThemeSelector = ({
	isSettings,
	isModalOpen,
	setProfileDropdownVisible,
	setProfileMenuOpen,
	setIsModalSettingsOpened,
}: {
	isSettings?: boolean;
	isModalOpen?: boolean;
	setIsModalSettingsOpened?: ((value: boolean) => void | undefined) | undefined;
	setProfileDropdownVisible?:
		| ((value: boolean) => void | undefined)
		| undefined;
	setProfileMenuOpen?: ((value: boolean) => void | undefined) | undefined;
}) => {
	const { toggleTheme } = useTheme();
	const {t} = useTranslation()
	const getThemeDetails = useTypedSelector(
		state => state.settings.settings.getTheme,
	);
	const [theme, setTheme] = useState('');
	const [selectedTheme, setSelectedTheme] = useState(getThemeDetails.name);
	const dispatch = useTypedDispatch();
	const [isLocked, setLocked] = useState(false);
	const handleThemeChange = async (value: string) => {
		setTheme(value);
		toggleTheme(value);
		const payload = {
			name: value,
			locked: isLocked,
		};
		isSettings && (await dispatch(postTheme(payload)));
		isSettings && setIsModalSettingsOpened?.(false);
	};

	useEffect(() => {
		const theme = getThemeDetails?.name || Cookies?.get('theme') || THEME.DEFAULT;
		setTheme(theme);
		setLocked(getThemeDetails.locked);
	}, [getThemeDetails]);

	useEffect(() => {
		const theme = getThemeDetails?.name || Cookies?.get('theme') || THEME.DEFAULT;
		toggleTheme(theme);
		setTheme(theme);
		setLocked(getThemeDetails.locked);
	}, [isModalOpen]);

	const healthStatus = () => {
		const menu = (
			<div className="flex items-center justify-center custom-dropdown-menu-sidebar">
				<div
					style={{
						right: '95.9%',
						position: 'absolute',
					}}>
					{<FaCaretLeft color="white" size={20} />}
				</div>
				<Menu
					onMouseLeave={() => {
						setProfileDropdownVisible?.(false);
					}}>
					<p className="p-2 font-semibold">{t('Admin.data.theme.changeTheme')}</p>
					<hr />
					{Object.entries(themeColors).map(([status, color]) => {
						const isActive = theme === status;
						return (
							<Menu.Item
								key={status}
								onClick={() => {
									handleThemeChange(status);
									setProfileDropdownVisible?.(false);
									setProfileMenuOpen?.(false);
								}}
								className={isActive ? 'bg-gray-100' : ''}
								style={{ color: isActive ? 'var(--text-color-root)' : '' }}>
								<Space className="flex items-center hover:text-black">
									<span
										className="hover:bg-slate-200"
										style={{
											display: 'inline-block',
											width: '20px',
											height: '20px',
											borderRadius: '50%',
											backgroundColor: color,
											marginTop: '6px',
										}}
									/>
									<span>
										{status.charAt(0).toUpperCase() + status.slice(1)}
									</span>
								</Space>
							</Menu.Item>
						);
					})}
				</Menu>
			</div>
		);

		return (
			<div className="flex justify-between items-center">
				<div className="align-middle mt-[0.20rem] pr-2">
					<Popover placement="right" showArrow={false}>
						<Dropdown
							overlay={menu}
							placement="right"
							trigger={['hover']}
							onOpenChange={visible => {
								setProfileDropdownVisible?.(visible);
							}}
							overlayStyle={{
								position: 'absolute',
								left: '210px',
							}}>
							<p>{t('Admin.data.theme.applyTheme')}</p>
						</Dropdown>
					</Popover>
				</div>
			</div>
		);
	};

	return (
		<>
			{isSettings ? (
				<div style={{ textAlign: 'center', marginTop: '20px' }}>
					<div
						style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
						{themes.map(item => (
							<>
								<Card
									key={item.name}
									onClick={() => {
										setSelectedTheme(item.name);
										document.documentElement.setAttribute(
											'data-theme',
											item.name,
										);
									}}
									className="theme-card"
									style={{
										border:
											selectedTheme === item.name
												? `2px solid ${item.color}`
												: '1px solid #ccc',
										borderRadius: '10px',
										cursor: 'pointer',
										padding: '2px',
										textAlign: 'center',
									}}>
									<img
										src={item.file}
										alt={item.name}
										style={{ width: '100%' }}
									/>
									<p
										style={{
											marginTop: '8px',
											fontWeight:
												selectedTheme === item.name ? 'bold' : 'normal',
										}}>
										{item.name.charAt(0).toUpperCase() + item.name.slice(1)}
									</p>
								</Card>
							</>
						))}
					</div>
					<div className="flex items-center justify-center gap-2 mt-4">
						<Checkbox checked={isLocked} onClick={() => setLocked(!isLocked)} />
						<p>{t('Admin.data.theme.blockUser')}</p>
					</div>
					<div className="gap-2 flex items-center justify-center">
						<Button
							style={{ marginTop: '10px', backgroundColor:"var(--button-color-primary)", color:'#fff' }}
							onClick={() => handleThemeChange(selectedTheme)}>
							{t('Admin.data.menu.aiAssistant.aiAssistantPrograms.save')}
						</Button>
						<Button
							style={{ marginTop: '10px' }}
							onClick={() => {
								setIsModalSettingsOpened?.(false);
								document.documentElement.setAttribute(
									'data-theme',
									getThemeDetails.name,
								);
							}}>
							{t('Admin.data.rehab.rehabPreAssessment.closeText')}
						</Button>
					</div>
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '10px',
						justifyContent: 'center',
					}}>
					{healthStatus()}
				</div>
			)}
		</>
	);
};

export default ThemeSelector;
