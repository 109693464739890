import React from 'react';
import { Radar } from '@ant-design/plots';
import { Card } from 'antd';
import { useTypedSelector } from '@stores/index';
import { useTranslation } from 'react-i18next';

const RadarChart = () => {
	const { selectedRom } = useTypedSelector(state => state.rom.customRom);
	const {t} = useTranslation()

	const calculateAverageScoresByJointAndSide = () => {
		const joints: Record<string, { total: number; count: number }> = {};

		selectedRom?.romPatientResults?.forEach(item => {
			item?.results?.map(data => {
				const measure =
					data?.romProgramExercise?.strapiOmniRomExercise?.name ||
					data?.romProgramExercise?.exerciseLibrary?.title ||
					t('Patient.data.omnirom.noTitle');
				const joint = measure;
				const score = data.value || 0;

				if (!joints[joint]) {
					joints[joint] = { total: 0, count: 0 };
				}
				joints[joint].total += score;
				joints[joint].count += 1;
			});
		});

		return Object.keys(joints).map(joint => ({
			joint,
			score: (joints[joint].total / joints[joint].count).toFixed(2),
		}));
	};

	const averageScores = calculateAverageScoresByJointAndSide();

	const maxScore = Math.max(
		...(selectedRom?.romPatientResults.flatMap(
			item => item?.results?.map(data => data.value || 100)!,
		) || [0]),
	);

	const config = {
		data: averageScores.map(item => ({
			...item,
			score: parseFloat(item.score),
		})),
		xField: 'joint',
		yField: 'score',
		meta: {
			score: {
				min: 0,
				max: maxScore,
			},
		},
		xAxis: {
			line: null,
			grid: null,
			tickLine: null,
			label: {
				autoRotate: true,
				autoHide: true, 
				autoEllipsis: false, 
				style: {
					maxWidth: 80,
					wordWrap: 'break-word',
					textAlign: 'center',
				},
				formatter: (text: string) => text.replace(/\s+/g, '\n'),
			},
		},
		yAxis: {
			label: null,
			line: null,
			tickLine: null,
			grid: {
				lineStyle: {
					lineDash: null,
				},
				alternateColor: 'rgb(0, 0, 0, 0.05)',
			},
		},
		area: {
			style: {
				fillOpacity: 0.7,
			},
		},
		tooltip: {
			showTitle: true,
			showMarkers: false,
			domStyles: {
				'g2-tooltip': {
					padding: '0px 12px 12px',
					backgroundColor: '#ffffff',
					boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
					borderRadius: '6px',
				},
				'g2-tooltip-title': {
					fontSize: '14px',
					fontWeight: 'bold',
					marginBottom: '8px',
				},
				'g2-tooltip-list-item': {
					fontSize: '12px',
					marginBottom: '4px',
				},
			},
		},
		color: '#ccff00',
	};

	return (
		<Card className="bg-gray-50 radar-chart" style={{ height: '100%' }}>
			<Radar {...config} />
		</Card>
	);
};

export default RadarChart;
