import { PageHeader } from '@ant-design/pro-layout';
import ActivityCalender from '@atoms/ActivityCalender';
import UserInfo from '@pages/Contacts/Details/UserInfo';
import { ActivityStreamDataProps, EvaluationItem } from '@stores/interfaces';
import { Avatar, Empty, message, Spin, Tooltip } from 'antd';
import ActivityStreamPanel from './ActivityStreamPanel/ActivityStreamPanel';
import { Share06 } from '@carespace-icons/general/share06';
import { ArrowUp } from '@carespace-icons/arrows/arrowUp';
import { useTypedDispatch, useTypedSelector } from '@stores/index';
import { painAssessmentInfoAction } from '@stores/painAssessment';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './ActivityStreamData.css';
import { AddToReports } from '@atoms/AddToReports';
import { XClose } from '@carespace-icons/general/xClose';
import { clearReports } from '@stores/report/reports';
import { Menu01 } from '@carespace-icons/general/menu01';
import { setCollapsible } from '@stores/aiAssistant/aiAssistant';
export default function ActivityStreamData(props: ActivityStreamDataProps) {
	const {
		scrollEnabled,
		isHeader,
		evaluationData,
		isLoading,
		handleLoad,
		functionalData,
		eventData,
		isReply,
		handleShare,
		updateOmniROMData,
		isFilter,
		clearFilter,
		updateData,
		handleScrollTo,
		lastMessageRef,
	} = props;
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const user = useTypedSelector(state => state.user);
	const selectedUser = useTypedSelector(
		state => state.contacts.main.selectedUser,
	);
	const reportIds = useTypedSelector(state => state.reports.reportIds)
	const reportCount = Object.keys(reportIds).filter(key => key !== 'romResultsIds').flatMap(key => reportIds[key]).length;
	const isCollapsible = useTypedSelector(state => state.aiAssistant.aiAssistant.isCollapsible)

	const onNameClick = () => {
		dispatch(painAssessmentInfoAction.onContactClick(true));
	};

	const getUsersName = (user: {
		profile: {
			fullName: string;
			firstName: string;
			lastName: string;
			email: string;
		};
	}) => {
		if (user?.profile?.fullName) return user?.profile?.fullName;
		if (user?.profile?.firstName && user?.profile?.lastName)
			return `${user?.profile?.firstName} ${user?.profile?.lastName}`;
		if (user?.profile?.firstName) return user?.profile?.firstName;
		return user?.profile?.email;
	};

	return (
		<div className="custom-activity-stream">
			<div className={`${isHeader !== false && 'custom-header'}`}>
				{isHeader !== false && (
					<>
						<PageHeader
							className="custom-container"
							title={
								<div className="relative-index">
									<div className="flexCenter">
										<div
											className="cursor-pointer p-2"
											onClick={() => dispatch(setCollapsible(!isCollapsible))}>
											<Menu01 color="stroke-gray-400" />
										</div>
										<div>
											{selectedUser?.profile?.imageUrl ||
											user?.profile?.imageUrl ? (
												<Avatar
													src={
														user?.isPhysioterapist
															? selectedUser?.profile?.imageUrl
															: user?.profile?.imageUrl
													}
													alt="avatar"
													size={50}
												/>
											) : (
												<Avatar
													style={{
														backgroundColor: user?.isPhysioterapist
															? selectedUser?.profile?.avatarColor
															: user?.profile?.avatarColor,
													}}
													className="custom-inline-flex"
													alt="avatar"
													size={50}>
													{user?.isPhysioterapist
														? selectedUser?.profile?.firstName
																?.charAt(0)
																?.toUpperCase()
														: user?.profile?.firstName
																?.charAt(0)
																?.toUpperCase()}
												</Avatar>
											)}
										</div>
										<div>
											<span onClick={onNameClick} className="custom-heading">
												{user.isPhysioterapist
													? getUsersName(selectedUser)
													: user?.profile?.fullName}
											</span>
											<div onClick={onNameClick} className="custom-styles">
												{user.isPhysioterapist
													? selectedUser?.profile?.email
													: user?.profile?.email}
											</div>
										</div>
									</div>
								</div>
							}>
							<div className="custom-layout">
								<UserInfo
									userDetails={user.isPhysioterapist ? selectedUser : user}
									functionalData={
										user.isPhysioterapist
											? selectedUser?.functionalGoals[0]?.functionalGoalsIds
											: user?.functionalGoals[0]?.functionalGoalsIds
									}
								/>
							</div>
						</PageHeader>
					</>
				)}
				<div
					className="custom-header-inherit"
					style={{ marginRight: isHeader === undefined ? '0.90rem' : '0rem' }}>
					<ActivityCalender
						activityStreamData={evaluationData}
						updateOmniROMData={updateOmniROMData}
						isFilter={isFilter}
						clearFilter={clearFilter}
					/>
				</div>
			</div>
			<div className="custom-activity-report-container">
				<div className="custom-activity-report-inner-container">
					{reportCount > 0 && (
						<>
							<AddToReports />
							<Tooltip
								placement="topLeft"
								title={t('Patient.data.activityStream.clear')}>
								<div
									onClick={() => {
										dispatch(clearReports());
										message.success(t('Patient.data.activityStream.clearMsg'));
									}}>
									<XClose color="stroke-gray-400" />
								</div>
							</Tooltip>
						</>
					)}
				</div>
			</div>
			{isLoading ? (
				<Spin className="fullscreen" tip="Loading" size="large" />
			) : (
				<>
					{evaluationData && evaluationData.length > 0 ? (
						<div
							className={`custom-container-item ${isReply ? 'custom-mb-6rem' : 'custom-mb-0'}`}
							style={{
								padding: isHeader !== false ? '1rem' : '1rem 1rem 5rem 1rem',
								marginTop: isHeader !== false ? '14.5rem' : '',
							}}>
							{updateData?.pagination.hasNextPage && (
								<div className="flexItemCenter">
									<div className="custom-arrow-icon" onClick={handleLoad}>
										<ArrowUp />
									</div>
								</div>
							)}
							{evaluationData.map((item: EvaluationItem, index: number) => (
								<div className="itemCenter" key={item.data.activityStreamId}>
									<div className="padding">
										<div
											className="custom-activity-id"
											id={`evaluationItem-${item.data.activityStreamId}`}>
											<span className="custom-date">
												{moment(item.data.createdAt)
													.local()
													.format('MMMM DD YYYY LT')}
											</span>
										</div>
									</div>
									<div className="flex-row-items-center">
										<ActivityStreamPanel
											scrollEnabled={scrollEnabled}
											index={index}
											item={item}
											evaluationData={evaluationData}
											lastMessageRef={lastMessageRef}
											handleScrollTo={handleScrollTo}
										/>
										{item?.data?.activityStream?.evaluationSessionId ||
										item?.data?.activityStream?.programSessionId ||
										item?.data?.activityStream?.surveyResultId ||
										item?.data?.activityStream?.romSessionId ? (
											<div
												className="custom-share-icon"
												onClick={() => handleShare(item)}>
												<Share06 color="stroke-gray-600" />
											</div>
										) : null}
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="custom-empty-container">
							{evaluationData && evaluationData.length === 0 && (
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
}
