import { Button, Card, QRCode, Typography, message } from "antd";
import ReactQuill from "react-quill"

const toolbarOptions = [
	[{ font: [] }],
	[{ size: ['small', false, 'large', 'huge'] }],
	['bold', 'italic', 'underline', 'strike'],
	[{ color: [] }, { background: [] }],
	[{ align: [] }],
	[{ list: 'ordered' }, { list: 'bullet' }],
	['blockquote', 'link', 'image'],
	['clean'],
];

const { Paragraph } = Typography;

interface TextEditorType {
  content: string,
  setContent: (value:string) => void
  activeKey: string | number
  instanceLink: string
  username: string
  password: string
  inviteCode: string
}

const TextEditor = (props: TextEditorType) => {

  const {content, setContent, activeKey, instanceLink, username, password, inviteCode} = {...props}

  return (
    <>
    <ReactQuill
        modules={{ toolbar: toolbarOptions }}
        theme="snow"
        value={content}
        onChange={setContent}
        style={{ marginTop: 10 }}
        className="custom-quill test"
      />
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '2fr 3fr',
						paddingTop: '16px',
						paddingBottom: '16px',
						gap: '16px',
						alignItems: 'start',
					}}>
					{
						<Card
							title="Credential Informations:"
							style={{
								gridColumn: 'span 1',
								borderRadius: '8px',
								paddingBottom: 0
							}}>
							<Paragraph>
								<strong>Instance Link:</strong>{' '}
								<a
									href="#"
									style={{ color: '#1890ff', textDecoration: 'none' }}>
									{instanceLink}
								</a>
							</Paragraph>
							<Paragraph>
								<strong>Username:</strong> {username}
							</Paragraph>
							<Paragraph>
								<strong>Password:</strong> {password}
							</Paragraph>
							<Paragraph style={{ display: 'flex', alignItems: 'center' }}>
								<strong>Invite Code:</strong> {inviteCode}
							</Paragraph>
						</Card>
					}
					<Card
						title="Download App:"
						style={{
							gridColumn: 'span 1',
							borderRadius: '8px',
							height: '100%'
						}}>
						<div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '16px', height: '100%' }}>
							<QRCode
								value="https://apps.apple.com/in/app/carespaceai/id6450214866"
								size={90}
								bordered={false}
							/>
							<img
								src="/images/dashboard/app-store.png"
								alt="Apple Store"
								style={{
									height: '90px',
									cursor: 'pointer',
								}}
								onClick={() =>
									window.open(
										'https://apps.apple.com/in/app/carespaceai/id6450214866',
										'_blank',
										'noopener,noreferrer',
									)
								}
							/>
						</div>
					</Card>
				</div>
    </>
  )
}

export default TextEditor;