import { useState } from 'react';
import { Table, Modal, Tag, Flex, Typography, Popover, Collapse } from 'antd';
import {
	PictureOutlined,
	MinusOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { InfoCircle } from '@carespace-icons/general/infoCircle';
import { useTypedSelector } from '@stores/index';
import { OmniRomRomPatientResult } from '@stores/interfaces';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ContentRomResult = () => {
	const { t } = useTranslation();
	const dataSource = useTypedSelector(
		state => state.rom.customRom?.selectedRom?.romPatientResults,
	);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);

	const showModal = (src: string) => {
		setSelectedImage(src);
		setModalOpen(true);
	};

	const handleCancelModalLightBox = () => {
		setModalOpen(false);
		setSelectedImage(null);
	};

	const categories = [
		{
			name: t('Patient.data.omnirom.optimalMobility'),
			range: '90 - 100',
			color: '#52c41a',
			scoreRange: [90, 100],
			description: t('Patient.data.omnirom.optimalMobilityDesc'),
		},
		{
			name: t('Patient.data.omnirom.functionalMobility'),
			range: '75 - 89',
			color: '#52c41a',
			scoreRange: [75, 89],
			description: t('Patient.data.omnirom.functionalMobilityDesc'),
		},
		{
			name: t('Patient.data.omnirom.moderateRestriction'),
			range: '60 - 74',
			color: '#faad14',
			scoreRange: [60, 74],
			description: t('Patient.data.omnirom.moderateRestrictionDesc'),
		},
		{
			name: t('Patient.data.omnirom.limitedMobility'),
			range: '40 - 59',
			color: '#faad14',
			scoreRange: [40, 59],
			description: t('Patient.data.omnirom.limitedMobilityDesc'),
		},
		{
			name: t('Patient.data.omnirom.restrictedMobility'),
			range: '20 - 39',
			color: '#d9363e',
			scoreRange: [20, 39],
			description: t('Patient.data.omnirom.restrictedMobilityDesc'),
		},
		{
			name: t('Patient.data.omnirom.criticallyImpaired'),
			range: 'Below 20',
			color: '#d9363e',
			scoreRange: [0, 19],
			description: t('Patient.data.omnirom.criticallyImpairedDesc'),
		},
	];

	const flattenDataSource = dataSource
		?.map(item => item?.results?.map(result => ({ ...item, ...result })))
		?.flat();

	const getCategory = (score: number) => {
		return categories.find(
			({ scoreRange }) => score >= scoreRange[0] && score <= scoreRange[1],
		);
	};

	const calculateScore = (normal: number, value: number, id: number) => {
		if (normal === 0) {
			return Math.max(100 - value, 0);
		}
		let score = 0;
		if ([21, 17, 4, 11]?.includes(id)) {
			if (value === 0) return 100;
			score = Math.round(100 - (value / normal) * 100);
		} else {
			score = Math.round((value / normal) * 100);
		}
		return Math.min(Math.max(score, 0), 100);
	};

	const categorizedData = flattenDataSource?.map(record => {
		const score = calculateScore(record?.normal!, record?.value!, record?.id);
		const category = categories.find(({ scoreRange }) => {
			return score >= scoreRange[0] && score <= scoreRange[1];
		});
		return {
			...record,
			category: category ? category.name : 'Uncategorized',
			score,
		};
	});

	const groupedData = categories?.map(category => {
		const filteredData = categorizedData?.filter(
			record => record?.category === category?.name,
		);
		return {
			categoryName: category?.name,
			categoryColor: category?.color,
			data: filteredData,
			range: category?.range,
			categoryDescription: category?.description,
		};
	});

	const columns = [
		{
			title: '',
			dataIndex: 'screenshot',
			key: 'screenshot',
			width: '40px',
			render: (src: string) => (
				<PictureOutlined
					style={{ fontSize: 22, cursor: 'pointer', color: '#999' }}
					onClick={() => showModal(src)}
				/>
			),
		},
		{
			title: t('Patient.data.omnirom.measure'),
			dataIndex: 'title',
			key: 'title',
			width: '100%',
			onCell: () => ({ style: { flex: 1 } }),
			render: (_: string, record: OmniRomRomPatientResult) => (
				<Text>
					{record?.romProgramExercise?.strapiOmniRomExercise?.name ||
						record?.title}
				</Text>
			),
		},
		{
			title: (
				<Popover content="Standard range of motion values." title="Normal">
					<Flex align="space-between" gap="small">
						<span>{t('Patient.data.omnirom.normal')}</span>
						<span className="cursor-pointer">
							<InfoCircle width={16} height={16} color="stroke-gray-500" />
						</span>
					</Flex>
				</Popover>
			),
			dataIndex: 'normal',
			key: 'normal',
			align: 'center',
			render: (_: string, record: OmniRomRomPatientResult) => {
				const normalValue = record?.normal || 0;
				return <Text type="secondary">{normalValue}</Text>;
			},
		},
		{
			title: (
				<Popover content="Values calibrated to OSHA standards." title="WFL">
					<Flex align="space-between" gap="small">
						<span>{t('Patient.data.omnirom.wfl')}</span>
						<span className="cursor-pointer">
							<InfoCircle width={16} height={16} color="stroke-gray-500" />
						</span>
					</Flex>
				</Popover>
			),
			dataIndex: 'wfl',
			key: 'wfl',
			align: 'center',
			render: (_: string, record: OmniRomRomPatientResult) => {
				const wflValue = record?.wfl || 0;
				return <Text type="secondary">{wflValue}</Text>;
			},
		},
		{
			title: t('Patient.data.omnirom.value'),
			dataIndex: 'value',
			key: 'value',
			align: 'center',
			render: (_: string, record: OmniRomRomPatientResult) => {
				const score = calculateScore(record.normal, record.value, record.id);
				const category = getCategory(score);
				return <span style={{ color: category?.color }}>{record?.value}</span>;
			},
		},
		{
			title: t('Patient.data.omnirom.ranking'),
			dataIndex: 'ranking',
			key: 'ranking',
			align: 'center',
			render: (_: string, record: OmniRomRomPatientResult) => {
				const score = record?.score || 0;
				const category = getCategory(score);
				return (
					<span style={{ color: category?.color, fontWeight: 'bold' }}>
						{calculateRanking(score)}
					</span>
				);
			},
		},
		{
			title: t('Patient.data.omnirom.score'),
			dataIndex: 'score',
			key: 'score',
			align: 'center',
			render: (finalScore: number) => {
				const category = getCategory(finalScore);
				return (
					<Tag
						style={{ marginInlineEnd: 0, width: '100%', textAlign: 'center' }}
						color={category?.color || 'default'}>
						{finalScore}
					</Tag>
				);
			},
		},
	];

	const calculateRanking = (score: number) => {
		if (score >= 91) return 10;
		if (score >= 81) return 9;
		if (score >= 71) return 8;
		if (score >= 61) return 7;
		if (score >= 51) return 6;
		if (score >= 41) return 5;
		if (score >= 31) return 4;
		if (score >= 21) return 3;
		if (score >= 11) return 2;
		if (score >= 1) return 1;
		return 0;
	};

	const defaultActiveKeys = groupedData
		?.map((category, index) =>
			category?.data && category?.data.length > 0 ? index.toString() : null,
		)
		?.filter(index => index !== null);

	return (
		<div>
			<Collapse
				accordion={false}
				bordered={false}
				expandIcon={({ isActive }) =>
					isActive ? <MinusOutlined /> : <PlusOutlined />
				}
				className="collapse-categories-results"
				style={{ background: 'transparent' }}
				defaultActiveKey={defaultActiveKeys}>
				{groupedData?.map((category, index) => (
					<Collapse.Panel
						key={index?.toString()}
						data-color={category?.categoryName}
						className="header-panel !bg-gray-50 !border !border-gray-200 !rounded-lg my-1.5"
						header={
							<Flex justify="space-between" align="flex-start">
								<span>
									{`${category?.categoryName}: `}
									<Text type="secondary">{category?.categoryDescription}</Text>
								</span>
								<Tag
									style={{ marginInlineEnd: 0 }}
									color={category?.categoryColor}>
									{category?.range}
								</Tag>
							</Flex>
						}
						style={{
							borderLeft: `4px solid ${category.categoryColor}`,
							margin: '16px 0',
						}}>
						<Table
							bordered
							dataSource={category?.data}
							columns={columns}
							pagination={false}
							size="small"
						/>
					</Collapse.Panel>
				))}
			</Collapse>
			<Modal
				width={1134}
				className="lightbox-modal"
				open={modalOpen}
				footer={null}
				onCancel={handleCancelModalLightBox}
				centered>
				{selectedImage && (
					<img
						src={selectedImage}
						alt="Screenshot"
						style={{ width: '100%', display: 'block' }}
					/>
				)}
			</Modal>
		</div>
	);
};

export default ContentRomResult;
